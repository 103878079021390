import React from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import IconKYCRejected from "resources/img/icons/account-rejected.svg";
import IconKYCAccepted from "resources/img/icons/account-verified.svg";
import IconBilling from "resources/img/icons/billing.svg";
import IconDashboard from "resources/img/icons/blocks.svg";
import IconDevelopers from "resources/img/icons/code.svg";
import IconSettings from "resources/img/icons/cog.svg";
import IconMetrics from "resources/img/icons/graph.svg";
import IconJobList from "resources/img/icons/hamburger.svg";
import IconSupport from "resources/img/icons/icon_support.svg";
import IconInvite from "resources/img/icons/invite.svg";
import IconKYCInProgress from "resources/img/icons/kyc_in_progress.svg";
import IconKYCSubmitted from "resources/img/icons/kyc_submitted.svg";
import IconJob from "resources/img/icons/play.svg";
import IconSmileLinks from "resources/img/icons/smile_links_icon.svg";
import IconIDAvailability from "resources/img/icons/status.svg";
import IconKYCReview from "resources/img/icons/under-review.svg";
import IconUser from "resources/img/icons/user-button.svg";
import IconNIMC from "resources/img/id_provider_logos/NG/NG_NATIONAL_ID.svg";
import IconStartKYC from "resources/img/start-kyc.png";
import { fetchPartnerInfo } from "util/api_util";
import { canUserView, userHasPermission } from "util/route_util";
import { getAdminPartnerId } from "util/selectors";
import { SidebarLink } from "./sidebar_link";

function Sidebar() {
  const { userType } = useAuthenticatedUser();
  const adminPartnerId = getAdminPartnerId();
  const [partnerInfo, setPartnerInfo] = React.useState();
  const [closeModal, setCloseModal] = React.useState(false);

  const { onEnvironmentChange, environment: displayEnvironment } =
    React.useContext(DisplayEnvironment);

  const history = useHistory();
  const currentUrl = useLocation().pathname;

  React.useEffect(() => {
    const data = adminPartnerId ? { partner: adminPartnerId } : {};
    fetchPartnerInfo(data)
      .then((res) => {
        setPartnerInfo(res?.partner);
      })
      .catch((err) => console.error(err));
  }, [displayEnvironment]);

  const toggleKYC = () => {
    onCloseModal();
    history.push("/partner/kyc_form");

    if (
      partnerInfo?.company?.kyc_state === "accepted" &&
      window?.analytics?.track
    ) {
      window.analytics.track("Activate NIN Tokenization");
    }
  };

  const renderNINV2Button = () => {
    const isPartner = userType === "partner";
    const ninState = partnerInfo?.company?.nin_state;
    const newKycState = partnerInfo?.company?.kyc_state;

    if (newKycState !== "accepted" || ninState === "accepted") {
      return;
    }

    return (
      <SidebarLink
        to="/partner/nin_v2"
        iconSrc={IconNIMC}
        iconSize="medium"
        className="sidebar__nav-element-container--white"
        allowed={isPartner && userHasPermission(["partner write"])}
      >
        <div className="sidebar__kyc-text-container">
          <p className="sidebar__nav-element" onClick={() => toggleKYC()}>
            Activate NIN Tokenization
          </p>
        </div>
      </SidebarLink>
    );
  };

  const renderKYCButton = () => {
    const kycState = partnerInfo?.company?.kyc_state;
    if (!kycState) {
      return;
    }
    const icon = {
      not_started: IconUser,
      in_progress: IconKYCInProgress,
      submitted: IconKYCSubmitted,
      under_review: IconKYCReview,
      accepted: IconKYCAccepted,
      rejected: IconKYCRejected,
    }[kycState];
    const text = {
      not_started: "Start Partner KYC",
      in_progress: "KYC in progress",
      submitted: "KYC Submitted",
      under_review: "Under Review",
      accepted: "Account Verified",
      rejected: "Account Not Verified",
    }[kycState];

    const isPartner = userType === "partner";
    const acceptedKYC = kycState !== "accepted";

    return (
      <SidebarLink
        to="/partner/kyc_form"
        iconSrc={icon}
        iconSize="medium"
        className="sidebar__nav-element-container--kyc"
        allowed={
          isPartner && userHasPermission(["partner write"]) && acceptedKYC
        }
      >
        <div className="sidebar__kyc-text-container">
          <p className="sidebar__nav-element">
            {text}
            <span className="sidebar__kyc-text-arrow" />
          </p>
          <p
            className="sidebar__nav-element--subtext"
            onClick={() => toggleKYC()}
          >
            Account Verification Status
          </p>
        </div>
      </SidebarLink>
    );
  };

  const renderProductionModal = () => {
    if (userType === "admin") {
      return;
    }
    return (
      <div className="kyc-form__modal">
        <div className="kyc-form__modal--body">
          <img src={IconStartKYC} alt="start kyc" />
          <h2>We need more information</h2>
          <p>
            We need more details about your company. Please submit your KYC
            details and you'll be in production in no time.
          </p>
          <button className="btn btn-primary" onClick={toggleKYC}>
            Start KYC
          </button>
          <button className="btn btn-secondary" onClick={closeModal}>
            I'll do this later
          </button>
        </div>
      </div>
    );
  };

  const onCloseModal = () => {
    onEnvironmentChange(false);
    setCloseModal(true);
  };

  const productionEnabled = partnerInfo?.production_enabled;

  const showProductionModal =
    productionEnabled === false &&
    displayEnvironment &&
    currentUrl.includes("kyc-form") &&
    !closeModal;

  const isPartner = userType === "partner";
  const isAdmin = userType === "admin";
  const environment = { false: "sandbox", true: "production" }[
    displayEnvironment
  ];

  const partnerName = partnerInfo?.company?.name;
  const partnerID = partnerInfo?.company?.partner_id;
  const nimcEnterpriseId = partnerInfo?.nimc_enterprise_id;
  const showEnterpriseId = partnerInfo?.show_nimc_enterprise_id;

  return (
    <div className="container__sidebar">
      {showProductionModal && renderProductionModal()}
      <div className="newsmile-heading-h1 sidebar__heading">{partnerName}</div>
      <div className="newsmile-heading-h1 sidebar__heading">
        PartnerID: {partnerID}
      </div>
      <hr className="sidebar__divider" />
      {showEnterpriseId && (
        <div className="newsmile-heading-h4 sidebar__heading">
          NIMC Enterprise Code: {nimcEnterpriseId}
        </div>
      )}

      <div className="sidebar__nav-elements">
        <SidebarLink iconSrc={IconDashboard} to="/partner/dashboard">
          Dashboard
        </SidebarLink>

        <SidebarLink
          allowed={isPartner}
          iconSrc={IconMetrics}
          to="/partner/analytics"
        >
          Analytics
        </SidebarLink>

        <SidebarLink
          iconSrc={IconDevelopers}
          to="/partner/developer_tools"
          allowed={
            (isAdmin || isPartner) && canUserView(["/partner/developer_tools"])
          }
        >
          Developers
        </SidebarLink>

        <SidebarLink
          iconSrc={IconJobList}
          to={
            isAdmin
              ? `/admin/partner_dashboards/${adminPartnerId}/job_list`
              : "/partner/job_list"
          }
          allowed={
            isAdmin ||
            (isPartner &&
              userHasPermission(["user_details read", "phone read"]))
          }
        >
          Job List
        </SidebarLink>

        <SidebarLink
          iconSrc={IconJobList}
          to={
            isAdmin
              ? `/admin/partner_dashboards/${adminPartnerId}/user_list`
              : "/partner/user_list"
          }
          allowed={
            isAdmin ||
            (isPartner &&
              userHasPermission(["user_details read", "phone read"]))
          }
        >
          User List
        </SidebarLink>

        <SidebarLink
          iconSrc={IconJob}
          to="/partner/web_app"
          allowed={
            isPartner &&
            userHasPermission(["selfie read", "id_card read"]) &&
            canUserView(["/partner/web_app"])
          }
        >
          SmartCheck&trade;
        </SidebarLink>
        <SidebarLink
          iconSrc={IconSmileLinks}
          to={`/partner/${environment}/smile_links`}
          path="/partner/:environment/smile_links"
          allowed={
            (isPartner || isAdmin) &&
            canUserView(["/partner/:environment/smile_links"])
          }
        >
          Smile Links
        </SidebarLink>
        <SidebarLink
          iconSrc={IconIDAvailability}
          to="/partner/id_status"
          allowed={isPartner || isAdmin}
        >
          ID API Status
        </SidebarLink>

        <SidebarLink iconSrc={IconBilling} to="/partner/billing">
          Billing
        </SidebarLink>

        <SidebarLink
          iconSrc={IconSettings}
          to="/partner/account_settings"
          allowed={isPartner}
        >
          Account Settings
        </SidebarLink>

        <SidebarLink
          iconSrc={IconSupport}
          to="/partner/support/tickets"
          allowed={isPartner}
        >
          Support
        </SidebarLink>

        {renderNINV2Button()}
        {renderKYCButton()}

        <div className="hr--lightest" />

        <SidebarLink
          className="sidebar__nav-element-container--invite"
          iconSrc={IconInvite}
          to="/settings/team"
          allowed={isPartner && canUserView(["/settings/team"])}
        >
          Invite Team Members
        </SidebarLink>
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
