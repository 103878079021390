import React from "react";
import styles from "./table.module.css";

export function Table({ children }) {
  return <table className={styles.table}>{children}</table>;
}

export function TableCaption({ children }) {
  return <caption>{children}</caption>;
}

export function TableHeader({ children }) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}
export function HeaderItem({ children }) {
  return <th>{children}</th>;
}
export function TableRow({ children }) {
  return <tr>{children}</tr>;
}

export function TableBody({ children }) {
  return <tbody>{children}</tbody>;
}

export function BodyItem({ children, style, className, onClick }) {
  return (
    <td style={style} className={className} onClick={onClick}>
      {children}
    </td>
  );
}

BodyItem.defaultProps = {
  onClick: () => {},
};
