import React, { useState } from "react";
import classNames from "classnames";
import { confirmPassword } from "util/api_util";

function ConfirmPassword({ action, data, cancel, light }) {
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  const callConfirmPassword = (e) => {
    e.preventDefault();
    setDisabled(true);
    confirmPassword({ password })
      .then((resp) => {
        if (resp.success) {
          action(data);
        } else {
          setError("Incorrect Password");
        }
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <div>
      {error && (
        <div className={classNames({ failure: true, "smile-dark": light })}>
          {error}
        </div>
      )}
      <form onSubmit={callConfirmPassword}>
        <div className="field">
          <label htmlFor="password">Confirm Password</label>
          <br />
          <input
            className="confirm-password__input input--line"
            autoFocus
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <br />
        <div className="actions">
          {cancel && (
            <button className="btn btn-primary" onClick={cancel}>
              Cancel
            </button>
          )}
          <button type="submit" className="btn btn-primary" disabled={disabled}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConfirmPassword;
