import React, { createRef, useEffect, useState } from "react";

const urlSlug = (phrase) => phrase.toLowerCase().replace(/\s/g, "-");

function Tabs({ labels, onTabChange, children }) {
  const tabList = createRef();
  const tabPanelsContainer = createRef();
  const [selectedTab, setSelectedTab] = useState(labels[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setShowDropdown(true);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectTab = (event, label) => {
    event.preventDefault();

    setSelectedTab(label);

    if (onTabChange) onTabChange();

    if (isOpen) setIsOpen(false);
  };

  return (
    <div className="tabs">
      <div className="position:relative">
        {showDropdown && (
          <button
            aria-expanded={isOpen}
            className="color-light tab-dropdown"
            onClick={toggleDropdown}
            type="button"
          >
            <span className="">{selectedTab}</span>
            <svg
              aria-hidden="true"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 10.6772C8.55 10.6772 8.25 10.5272 7.95 10.2272L0.45 2.72725C-0.15 2.12725 -0.15 1.22725 0.45 0.627246C1.05 0.0272461 1.95 0.0272461 2.55 0.627246L9 7.07725L15.45 0.627246C16.05 0.0272461 16.95 0.0272461 17.55 0.627246C18.15 1.22725 18.15 2.12725 17.55 2.72725L10.05 10.2272C9.75 10.5272 9.45 10.6772 9 10.6772Z"
                fill="#F8DED8"
              />
            </svg>
          </button>
        )}

        <ul
          ref={tabList}
          role={showDropdown ? "tablist" : undefined}
          className="color-light stack flow-space:--s--5"
        >
          {labels.map((label, i) => (
            <li
              role={showDropdown ? "presentation" : undefined}
              key={`tab${i + 1}`}
            >
              <a
                aria-selected={showDropdown && selectedTab === label && "true"}
                href={`#${urlSlug(label)}`}
                id={`tab${i + 1}`}
                onClick={(evt) => selectTab(evt, label)}
                role={showDropdown ? "tab" : undefined}
                tabIndex={
                  showDropdown && selectedTab !== label ? -1 : undefined
                }
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div ref={tabPanelsContainer}>
        {children.map((child, i) => (
          <section
            aria-labelledby={showDropdown && `tab${i + 1}`}
            hidden={showDropdown && selectedTab !== labels[i]}
            id={urlSlug(labels[i])}
            key={urlSlug(labels[i])}
            role={showDropdown ? "tabpanel" : undefined}
          >
            {child}
          </section>
        ))}
      </div>
    </div>
  );
}

export default Tabs;
