import React, { createContext, useState, useEffect } from "react";

import { fetchPartnerInfo } from "util/api_util";

export const getEnvironment = () =>
  ({
    true: "production",
    false: "sandbox",
  })[sessionStorage.getItem("displayEnvironment")] || "production";

const initial = {
  environment: undefined,
  enabled: false,
  viewAs: undefined, // 'admin' or 'partner'
  setEnabled: () => {},
  onEnvironmentChange: () => {},
};

export const createInitialEnvironment = () => {
  const baseState = sessionStorage.getItem("displayEnvironment");

  let onLoadState = baseState;

  // If this is a new window/tab, We check the url for the environment
  // we set the session storage
  // if no environment is found, we defer the initialization to {@link getProductionEnabled}
  const inSandbox = window.location?.pathname?.includes("sandbox");
  const inProduction = window.location?.pathname?.includes("production");
  if (inSandbox || inProduction) {
    onLoadState = inProduction === true;
    sessionStorage.setItem("displayEnvironment", String(onLoadState));
  }

  return { ...initial, environment: onLoadState };
};

export const DisplayEnvironment = createContext(createInitialEnvironment());

export function DisplayEnvironmentProvider({ children }) {
  const value = sessionStorage.getItem("displayEnvironment");
  // NOTE: sessionStorage does not maintain boolean types. It returns strings.
  // Here, we know the value will be a string because it just came from
  // sessionStorage, but elsewhere it might still be a boolean. Therefore
  // in other places cast the value to a string before evaluating.
  const [environment, setEnvironment] = useState(value === "true");
  const [enabled, setEnabled] = useState(false);
  const [viewAs, setViewAs] = useState(undefined);
  const onEnvironmentChange = (bool) => {
    // only allow toggling between environment when enabled
    if (enabled) {
      sessionStorage.setItem("displayEnvironment", bool);
      setEnvironment(bool);

      if (bool && window?.analytics?.track) {
        window.analytics.track("Toggle to Production");
      }
    }
  };

  useEffect(() => {
    if (localStorage.token) {
      (async function getProductionEnabled() {
        try {
          // this will exist if someone has refreshed the page rather than
          // freshly navigated to the portal
          const baseState = sessionStorage.getItem("displayEnvironment");
          const { partner } = await fetchPartnerInfo();
          const isProductionEnabled = partner?.production_enabled;
          let onLoadState = baseState;
          // if this is a page being freshly navigated to, show sandbox rather
          // production if production has not been enabled for that partner
          if (baseState === null) {
            onLoadState = isProductionEnabled === true;
          }
          const inSandbox = window.location?.pathname?.includes("sandbox");
          const inProduction =
            window.location?.pathname?.includes("production");
          if (inSandbox || inProduction) {
            onLoadState = inProduction === true;
          }
          sessionStorage.setItem("displayEnvironment", onLoadState);
          setEnvironment(onLoadState);
        } catch (error) {
          console.error(error);
          console.info(
            "[DisplayEnvironment] - Error: Failed to fetch partner info",
          );
        }
      })();
    }
  }, [localStorage.token]);

  return (
    <DisplayEnvironment.Provider
      value={{
        environment,
        enabled,
        setEnabled,
        viewAs,
        setViewAs,
        onEnvironmentChange,
      }}
    >
      {children}
    </DisplayEnvironment.Provider>
  );
}
