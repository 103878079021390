import React, { useEffect, useState } from "react";
import { Loader } from "components/reusable/Loader";
import SmileLock from "resources/img/brand/smile-id__symbol--32x32.svg";
import * as APIUtil from "util/api_util";

function LoginTFAPage() {
  const [loginWithRecoveryCode, setLoginWithRecoveryCode] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (isLoggedIn || !localStorage.preOTPUser) {
      window.location = "/";
    }
  }, [isLoggedIn, localStorage.preOTPUser]);

  const sendOTP = async (e) => {
    e.preventDefault();
    setError("");

    try {
      if (!OTPCode || OTPCode.length !== 6) {
        throw new Error("OTP Code should be 6 digits only");
      }

      const payload = {
        ...JSON.parse(localStorage.preOTPUser),
        otp_code: OTPCode,
      };

      const resp = await APIUtil.loginWithOTP(payload);

      if (resp.errors) throw resp.errors;
      localStorage.clear();
      if (resp.auth_token) localStorage.setItem("token", resp.auth_token);
      setIsLoading(false);
      setIsLoggedIn(true);
    } catch (e) {
      const error =
        e instanceof Error ? e.message : Array.isArray(e) ? e.join("\n") : e;

      setIsLoading(false);
      setError(error);
    }
  };

  const sendRecoveryCode = async (e) => {
    e.preventDefault();
    setError("");

    try {
      if (!recoveryCode || recoveryCode.length !== 10) {
        throw new Error("Recovery Code should be 10 digits only");
      }

      const payload = {
        ...JSON.parse(localStorage.preOTPUser),
        recovery_code: recoveryCode,
      };
      const resp = await APIUtil.loginWithRecoveryCode(payload);

      if (resp.errors) throw resp.errors;
      localStorage.clear();
      if (resp.auth_token) localStorage.setItem("token", resp.auth_token);
      setIsLoading(false);
      setIsLoggedIn(true);
    } catch (e) {
      const error =
        e instanceof Error ? e.message : Array.isArray(e) ? e.join("\n") : e;

      setIsLoading(false);
      setError(error);
    }
  };

  const switchMode = (e) => {
    e.preventDefault();
    setLoginWithRecoveryCode(!loginWithRecoveryCode);
  };

  const cancelLogIn = (e) => {
    e.preventDefault();

    localStorage.clear();
    window.location = "/";
  };

  return (
    <div className="cover | bgc-dark | authentication">
      <div className="center">
        <div className="box auth-box">
          <div className="stack">
            <div className="center">
              <img
                className="smile-login__icon"
                src={SmileLock}
                alt="smile logo"
              />
            </div>
            <h1 className="text-align:center">
              Log in with {loginWithRecoveryCode ? "Recovery Code" : "OTP"}
            </h1>

            {error && <p className="color-error">{error}</p>}

            <form noValidate name="enterOTPForm" className="stack">
              {!loginWithRecoveryCode && (
                <div className="stack" data-variant="tiny">
                  <div className="stack" data-variant="tiny">
                    <label htmlFor="totp-token">
                      Enter OTP generated in App
                    </label>
                    <input
                      autoFocus
                      id="totp-token"
                      className="input-text"
                      inputMode="numeric"
                      name="totp-token"
                      autoComplete="one-time-code"
                      placeholder="Enter OTP"
                      required
                      type="text"
                      pattern="([0-9]{6})|([0-9a-fA-F]{5}-?[0-9a-fA-F]{5})"
                      value={OTPCode}
                      onChange={(e) => setOTPCode(e.target.value)}
                    />
                  </div>
                  <button
                    disabled={isLoading}
                    className="btn-primary"
                    type="submit"
                    onClick={sendOTP}
                  >
                    {isLoading && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Log in
                  </button>
                  <button
                    disabled={isLoading}
                    className="btn--link"
                    type="button"
                    onClick={switchMode}
                  >
                    Log in with Recovery Code
                  </button>
                  <button
                    disabled={isLoading}
                    className="btn--link"
                    type="button"
                    onClick={cancelLogIn}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {loginWithRecoveryCode && (
                <div className="stack" data-variant="tiny">
                  <label htmlFor="totp-token">Enter Recovery Code</label>
                  <input
                    autoFocus
                    className="input-text"
                    id="totp-token"
                    name="totp-token"
                    placeholder="Recovery Code"
                    required
                    type="text"
                    pattern="([a-zA-Z0-9]{10}"
                    value={recoveryCode}
                    onChange={(e) => setRecoveryCode(e.target.value)}
                  />
                  <button
                    disabled={isLoading}
                    className="btn-primary"
                    type="button"
                    onClick={sendRecoveryCode}
                  >
                    {isLoading && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Log in
                  </button>
                  <button
                    disabled={isLoading}
                    className="btn--link"
                    type="button"
                    onClick={switchMode}
                  >
                    Log in with OTP
                  </button>
                  <button
                    disabled={isLoading}
                    className="btn--link"
                    type="button"
                    onClick={cancelLogIn}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginTFAPage;
