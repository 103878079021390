import React from "react";

function AmlTable({ data, columns, tableClassName, onRowClick }) {
  return (
    <table className={tableClassName}>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={`${column.name}-${index + 1}`}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr
            key={`${row.id}-${index + 1}`}
            onClick={onRowClick ? () => onRowClick(index) : undefined}
          >
            {columns.map((column, colIndex) => (
              <td key={`${column.name}-${colIndex + 1}`}>{row[column.name]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default AmlTable;
