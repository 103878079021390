import React from "react";
import { Link, useHistory } from "react-router-dom";
import Bot from "resources/img/icons/bot.svg";
import emptyWarning from "resources/img/icons/empty-warning.svg";
import Tick from "resources/img/icons/tick--green.svg";
import Warning from "resources/img/icons/warning.svg";
import {
  getProviderImage,
  getProviderImageJobType,
  unifyIDAPICountry,
} from "util/actions_helpers";
import { fetchIDStatusMetrics } from "util/api_util";
import { formatLocalisedDate, getCountry } from "util/format_helpers";
import { getUserType } from "util/selectors";

const filterDataByCountry = (data) =>
  ["GH", "KE", "NG", "UG", "ZA", "ZM"].map((country) => ({
    countryCode: country,
    country: getCountry(country),
    data: data.filter(
      (dataPoint) => unifyIDAPICountry(dataPoint.country) === country,
    ),
  }));

const ProviderRestrictedJobType = ({ provider }) => {
  const idVerificationJobType = getProviderImageJobType(provider);
  if (!idVerificationJobType) return null;
  return (
    <div className="id-provider-name__type">({idVerificationJobType})</div>
  );
};

function StatusCard({ status, userType }) {
  const history = useHistory();

  const country = getCountry(status.country) || status.country;

  const goToIDPage = (country, id_type, provider) => {
    const path = userType === "partner" ? "/partner" : "/admin";
    history.push(`${path}/id_status/${country}/${id_type}/${provider}`);
  };

  return (
    <div
      className="id-status-card"
      key={`${status.country}${status.id_type}${status.provider}`}
      onClick={() => goToIDPage(country, status.id_type, status.provider)}
    >
      <img
        alt="Id Provider Icon"
        src={getProviderImage(status.country, status.id_type, status.provider)}
      />
      <div className="id-provider-name">
        {status.id_type.replace(/_/g, " ")}
        <ProviderRestrictedJobType provider={status.provider} />
      </div>
      <div className="id-provider__timestamp-container">
        Latest:{" "}
        <span className="id-provider__timestamp">
          {formatLocalisedDate(status.created_at, "ddd MMM DD, YYYY HH:mm")}
        </span>
      </div>
      <div
        className={`id-provider__status-container result-element result-element--${status.status}`}
      >
        <span
          className={`result-element-dot result-element-dot--${status.status}`}
        />
        <span className="id-provider__status">{status.status}</span>
      </div>
    </div>
  );
}

function IDStatus() {
  const [loading, setLoading] = React.useState(true);
  const userType = getUserType();
  const [idStatuses, setIdStatuses] = React.useState([]);
  const [offlineServices, setOfflineServices] = React.useState(0);

  React.useEffect(() => {
    fetchIDStatusMetrics().then((data) => {
      setLoading(false);
      setIdStatuses(filterDataByCountry(data.points));
      setOfflineServices(
        data.points.filter((service) => service.status === "offline").length,
      );
    });
  }, []);

  const sections = idStatuses.map((section) => (
    <div className="id-status__section-container" key={section.country}>
      <h3
        className="id-status__section-name"
        data-testid={`id-api-authority-${section.countryCode}`}
      >
        {section.country} ID API Authorities
      </h3>
      {section.data.length > 0 ? (
        <div className="id-status__section-cards">
          {section.data.map((status) => (
            <StatusCard
              key={`${status.country}${status.id_type}${status.provider}`}
              status={status}
              userType={userType}
            />
          ))}
        </div>
      ) : (
        <div className="no-info__container id-status-card--no-info">
          <img
            className="icon--large"
            src={emptyWarning}
            alt="No information to show "
          />
          <div className="no-info">There is no information at this time.</div>
        </div>
      )}
    </div>
  ));

  return (
    <div className="id-status__container">
      <div className="id-status__header">
        <h1 className="id-status__heading">ID Authority API Status</h1>
        <div className="id-status__report-downtime">
          <div className="result-element-dot result-element-dot--offline" />
          <Link to="/partner/support/tickets">Report Downtime</Link>
        </div>
      </div>

      {userType === "partner" && (
        <div className="id-status__slack">
          <div className="id-status__slack-desc">
            <img src={Bot} alt="bot" />
            <div>
              <h3 className="text">
                Add the Smile ID Slackbot to your workspace
              </h3>
              <small className="subtext">
                Get notified when ID authorities experience service
                interruptions.
              </small>
            </div>
          </div>
          <a
            target="_blank"
            href="https://slack.com/oauth/v2/authorize?client_id=118579855904.6379808783014&scope=chat:write,commands,incoming-webhook&user_scope="
            rel="noreferrer"
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </div>
      )}
      {loading && (
        <div className="loader__container">
          <div className="loader loader--centered" />
        </div>
      )}

      {idStatuses.length > 0 && (
        <div>
          <div className="id-status__overview-container">
            <div className="id-status__overview-visual-container">
              <img
                className="id-status__overview-image icon--large"
                src={offlineServices > 0 ? Warning : Tick}
                alt="status"
              />
            </div>
            <div className="id-status__overview-description-container">
              <div className="id-status__overview-description">
                {offlineServices > 0 ? offlineServices : "All"} of the services
                have been&nbsp;
                <span
                  className={`id-status__overview-description-status ${
                    offlineServices > 0 ? "text--offline" : "text--online"
                  }`}
                >
                  {offlineServices > 0 ? "Offline" : "Online"}
                </span>
                &nbsp;in the past hour
              </div>
            </div>
          </div>
          <div className="id-status-cards__container">{sections}</div>
        </div>
      )}
    </div>
  );
}

export default IDStatus;
