import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import CountryPicker from "components/reusable/country_picker";
import CardIcon from "resources/img/icons/card.svg";
import { createInvoice, fetchWallet } from "util/api_util";
import {
  convertCurrency,
  formatCurrency,
  getCurrencyCountry,
  isEmpty,
} from "util/format_helpers";
import { CurrencyInput } from "./currency_input";
import {
  DLocalPaymentFlow,
  PaymentModalMessage,
  PendingPaymentModal,
} from "./dlocal_smart_fields";

/**
 * Formats a payload for a payment provider.
 * @param {object} state - The state of the component
 * @param {number} state.amount - The amount to be paid.
 * @param {string} state.currency - The currency of the amount.
 * @returns {{
 * amount: number,
 * currency: string,
 * partner_id: string|undefined
 * }} - body of the request.
 */
const generatedInvoiceRequest = ({ amount, currency }) => {
  const body = { amount, currency };
  if (localStorage.adminPartnerId) {
    body.partner_id = localStorage.adminPartnerId;
  }
  return body;
};

/**
 * Validates that amount is greater than a minimum funding amount.
 * @param {object} state - The state of the component.
 * @param {number} state.amount - The amount to validate.
 * @param {string} state.currency - The currency of the amount.
 * @param {object} state.exchangeRates - The exchange rates to USD indexed by ISO country code.
 * @returns {number} - minimum funding amount.
 * }
 */
const validateAmount = ({ amount, currency, exchangeRates }) => {
  // 20 US dollars in local currency.
  const minAmount = 20 * exchangeRates[currency];
  if (parseFloat(amount) < minAmount) {
    throw new Error(
      `${formatCurrency(minAmount, currency)} is the minimum funding amount.`,
    );
  }
  return minAmount;
};

/**
 * Default payment amounts by currency. Minimum value
 * approximately corresponds to 500 USD.
 */
const defaultPaymentAmounts = {
  USD: [500, 1000, 2000, "Other"],
  NGN: [200000, 400000, 800000, "Other"],
  KES: [50000, 100000, 200000, "Other"],
  ZAR: [5000, 10000, 20000, "Other"],
};

class FundWallet extends Component {
  constructor(props) {
    super(props);
    const queryParams = this.getParams();
    const currency = (
      queryParams.currency ||
      sessionStorage.getItem("currency") ||
      "USD"
    ).toUpperCase();
    this.state = {
      loading: false,
      disabled: false,
      user: jwtDecode(localStorage.token),
      currency,
      amount: defaultPaymentAmounts[currency]?.[0] ?? 500,
      quickAmount: defaultPaymentAmounts[currency]?.[0] ?? 500,
      modalOpen: false,
      flwvKey: undefined,
      dlocalKey: undefined,
      paymentStatus: queryParams.status?.toLowerCase(),
      amountPaid: queryParams.amount,
      paymentRef: queryParams.paymentRef,
    };
    this.setCurrency = this.setCurrency.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBackButton = this.onBackButton.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.paymentCallback = this.paymentCallback.bind(this);
    this.getData = this.getData.bind(this);
    this.redirectBack = this.redirectBack.bind(this);
    this.setQuickAmount = this.setQuickAmount.bind(this);
  }

  getParams() {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const result = {};
    for (const key of params.keys()) {
      result[key] = params.get(key);
    }
    return result;
  }

  componentDidMount() {
    sessionStorage.setItem("url", `/partner/billing/payment`);

    // when we redirect back to this component from stripe when want to clear
    // the query params, so when the user refresh the page manually they
    // don't get the payment success message
    const queryParams = this.getParams();
    if (Object.keys(queryParams).length > 0) {
      this.props.history.replace("/partner/billing/payment");
    } else {
      this.getData();
    }
  }

  getData() {
    const params = {};
    if (localStorage.adminPartnerId) {
      params.partner_id = localStorage.adminPartnerId;
    }

    this.setState({ loading: true, disabled: true });
    fetchWallet(params).then(
      ({ wallet_balance: walletBalance, exchange_rates: exchangeRates }) => {
        this.setState({
          walletBalance,
          exchangeRates,
          loading: false,
          disabled: false,
          minAmount: 20 * exchangeRates[this.state.currency],
        });
      },
    );
  }

  redirectBack() {
    this.props.history.push(window.location.pathname.replace("/payment", ""));
  }

  handleChange(event) {
    const key = event.target.name;
    // the value of an empty input field is always an empty string even when type=number
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === "") {
      this.setState({ [key]: event.target.value });
    }
  }

  async createInvoice() {
    try {
      this.setState({
        disabled: true,
        loading: true,
        error: undefined,
        minAmount: validateAmount(this.state),
      });
      const body = generatedInvoiceRequest(this.state);
      const resp = await createInvoice(body);
      if (body.currency.toLowerCase() === "usd" && resp.checkout_url) {
        window.open(resp.checkout_url, "_self");
      } else {
        this.setState({
          error: resp.error,
          invoice: resp.invoice,
          disabled: false,
          loading: false,
          dlocalKey: resp.dlocal_sf_key,
          paymentMethods: resp.payment_methods,
          flwvKey: resp.flwv_key,
        });
      }
    } catch (e) {
      this.setState({
        disabled: false,
        loading: false,
        error: e.message,
      });
    }
  }

  setCurrency(currency) {
    this.setState({
      currency,
      pause: true,
    });
    // It is very unfortunate but this set timeout is required, because otherwise
    // the modal loads with whatever the original currency/country is during the
    // initial page load.
    setTimeout(() => {
      this.setState({
        pause: false,
      });
    }, 100);
  }

  paymentCallback() {
    this.setState({
      amount: 500,
      amountPaid: undefined,
      disabled: true,
      dlocalKey: null,
      invoice: null,
      loading: true,
      paymentStatus: undefined,
      quickAmount: 500,
    });
    this.getData();
  }

  onBackButton() {
    this.setState({
      invoice: undefined,
      disabled: false,
      loading: false,
    });
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  }

  /**
   * Updates state with the quick amount selected by the user.
   * @param {number|string} option - amount of money in native currency or string Other
   * @returns {void}
   */
  setQuickAmount(option) {
    if (this.state.disabled) {
      return;
    }
    this.setState({
      amount: option === "Other" ? undefined : option,
      quickAmount: option,
    });
  }

  render() {
    const {
      amount,
      disabled,
      currency,
      dlocalKey,
      invoice,
      loading,
      paymentMethods,
      paymentStatus,
      quickAmount,
      walletBalance,
      paymentRef,
      amountPaid,
    } = this.state;

    const paymentAmounts = defaultPaymentAmounts?.[currency] ?? [];
    const options = paymentAmounts.map((option) => {
      const formattedAmount =
        option === "Other" ? option : formatCurrency(option, currency);
      return (
        <button
          type="button"
          key={option}
          onClick={() => this.setQuickAmount(option)}
          className={[
            "fund-wallet__options",
            quickAmount === option ? " active" : "",
            disabled ? " disabled" : "",
          ]
            .filter((x) => x)
            .map((x) => x.trim())
            .join(" ")}
        >
          <p className="fund-wallet__options-button-text">{formattedAmount}</p>
        </button>
      );
    });

    return (
      <>
        <div className="breadcrumbs">
          <a
            data-value="billing"
            className="breadcrumb-link breadcrumb-link--inactive"
            onClick={this.redirectBack}
          >
            Billing
          </a>
          <span> &gt; </span>
          <span>Fund Wallet</span>
        </div>
        <div className="web-app__container">
          <div className="web-app__container-header">
            <div className="web-app__container-header">
              <img
                className="web-app__header-icon icon--large"
                src={CardIcon}
                alt="lock"
              />
              <h1 className="metrics__header">Fund Wallet</h1>
            </div>
          </div>
          <div className="web-app__container-body">
            <div
              className={`web-app__wizard-loading ${loading ? "loader" : ""}`}
            />
            <div className="web-app__wizard-form web-app__wizard-form--top-level">
              <div className="web-app__wizard-error error">
                {this.state.error}
              </div>
              <div>
                <a
                  href="https://docs.usesmileid.com/getting-started/fund-your-wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Supported Payment Methods
                </a>
              </div>
              <div className="web-app__wizard-form-item">
                <CountryPicker
                  callback={this.setCurrency}
                  currency={currency}
                  disabled={disabled}
                />
                <h2>
                  Current Balance:{" "}
                  {formatCurrency(
                    parseFloat(convertCurrency(this, walletBalance ?? 0)),
                    currency,
                  )}
                </h2>
              </div>

              <div className="web-app__wizard-form-item">
                {options.length > 0 && <label className="label">Amount</label>}
                {options}

                {quickAmount === "Other" ? (
                  <CurrencyInput
                    autoFocus
                    id="other"
                    className="input"
                    name="amount"
                    type="number"
                    onChange={(amount) => {
                      this.setState({ amount });
                    }}
                    value={this.state.amount}
                    minimumValue={this.state.minAmount}
                    disabled={disabled}
                    currency={currency}
                  />
                ) : null}
              </div>
              <div className="web-app__wizard-buttons">
                <button
                  className="btn btn-primary web-app__wizard-button--submit"
                  onClick={() => this.createInvoice()}
                  disabled={disabled || loading}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {invoice && !isEmpty(dlocalKey) && (
          <DLocalPaymentFlow
            amount={parseFloat(amount)}
            invoiceId={invoice?.invoice_id}
            currency={currency}
            apiKey={dlocalKey}
            paymentMethods={paymentMethods}
            show={invoice && !isEmpty(dlocalKey)}
            onCancel={() => this.setState({ dlocalKey: null, invoice: null })}
            onComplete={() => this.paymentCallback()}
            partnerId={localStorage.adminPartnerId}
          />
        )}

        {!loading && !isEmpty(paymentStatus) && (
          <Modal
            show={!loading && !isEmpty(paymentStatus)}
            onClick={() => {
              this.setState({
                paymentStatus: undefined,
                amountPaid: undefined,
                loading: true,
                disabled: true,
              });
              this.getData();
            }}
            paymentStatus={paymentStatus}
            paymentRef={paymentRef}
            currency={currency}
            amount={amountPaid}
          />
        )}
      </>
    );
  }
}

function Modal({ paymentStatus, onClick, show, paymentRef, currency, amount }) {
  if (!show) {
    return null;
  }
  let title = "Payment Rejected";
  let message = "";
  const isPending = paymentStatus === "pending";
  const isApproved = paymentStatus === "approved";
  const isExpired = paymentStatus === "expired";

  if (isApproved) {
    title = "Funds Added Successfully!";
    message =
      "It may take a few moments for your balance to reflect your most recent payment.";
  } else if (isExpired) {
    title = "Session Expired";
    message = "Please retry payment";
  }

  if (isPending) {
    return (
      <PendingPaymentModal
        currency={currency}
        amount={amount}
        params={{
          message: "Please wait while we confirm payment.",
          payment_ref: paymentRef,
          country: getCurrencyCountry(currency),
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <PaymentModalMessage
      onClick={onClick}
      title={title}
      message={message}
      error={!isApproved}
    />
  );
}

export default FundWallet;
