import React, { StrictMode } from "react";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import AppContainer from "containers/app_container";
import { DisplayEnvironmentProvider } from "contexts/displayEnvironment";
import configureStore from "store/store";

const environment = import.meta.env.VITE_ENVIRONMENT || "local";

const interceptFetch = () => {
  const originalFetch = window.fetch;
  const newFetch = (input, init) => {
    const url =
      typeof input === "string" && input.startsWith("/")
        ? `http://localhost:3000${input}`
        : input.url;
    input = new Request(url, init);
    return originalFetch(input, init);
  };
  window.fetch = newFetch;
};

if (import.meta.env.MODE === "development") {
  // NOTE(solo): With CRA we could proxy all paths to the backend,
  // with vite we can only configure specific paths like /api and all it's sub paths.
  // we can't use the Vite proxy because we have so many paths and are not grouped
  // under a single /api base path. In development, I manually intercept fetch requests
  // and proxy them to the backend. I also enabled CORs in rails for the origin localhost:3001
  // Read more here[https://github.com/vitejs/vite/discussions/12137]
  interceptFetch();
}

if (environment !== "local") {
  Sentry.init({
    dsn: "https://5b5558704f684fa6a6ed8474963269dd@o1154186.ingest.sentry.io/6565514",
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: parseFloat(
      import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE || 0.01,
    ),
    replaysOnErrorSampleRate: parseFloat(
      import.meta.env.VITE_SENTRY_ON_ERROR_SAMPLE_RATE || 1.0,
    ),
    tracesSampleRate: parseFloat(
      import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.01,
    ),
    environment,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureStore();
window.store = store;

const launchdarklyKey = import.meta.env.VITE_LAUNCH_DARKLY_KEY;

(async () => {
  if (!launchdarklyKey) {
    return root.render(
      <Provider store={store}>
        <DisplayEnvironmentProvider>
          <StrictMode>
            <AppContainer />
          </StrictMode>
        </DisplayEnvironmentProvider>
      </Provider>,
    );
  }
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchdarklyKey,
  });

  root.render(
    <Provider store={store}>
      <LDProvider>
        <DisplayEnvironmentProvider>
          <StrictMode>
            <AppContainer />
          </StrictMode>
        </DisplayEnvironmentProvider>
      </LDProvider>
    </Provider>,
  );
})();
