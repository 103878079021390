import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { fetchSDKLink } from "util/api_util";
import { semverSort3 } from "util/selectors";

class SDK extends Component {
  constructor(props) {
    super(props);

    const user = jwtDecode(localStorage.token);
    this.state = {
      partner_id: user.partner_id,
      company: undefined,
      user,
      links: {},
      error: null,
      encrypted_partner_id: undefined,
      android: true,
    };
    this.downloadJSONFile = this.downloadJSONFile.bind(this);
    this.toggleSDK = this.toggleSDK.bind(this);
  }

  componentDidMount() {
    fetchSDKLink()
      .then((resp) => {
        let error;
        if (!resp.encrypted_partner_id) {
          error =
            "Unable to get configuration file. Please try refreshing the page";
        }
        this.setState({
          links: resp.sdk_links,
          encrypted_partner_id: resp.encrypted_partner_id,
          error: resp.error || error,
        });
      })
      .catch(() => {
        this.setState({
          error:
            "Unable to get configuration file. Please try refreshing the page",
        });
      });
  }

  buildConfig() {
    const data = {
      test_url: "https://sdktest.smileidentity.com/",
      test_lambda_url: "https://testapi.smileidentity.com/v1/",
      prod_url: "https://sdk.smileidentity.com/",
      prod_lambda_url: "https://api.smileidentity.com/v1/",
      auth_token: this.state.encrypted_partner_id,
      partner_id: this.state.partner_id,
      version: "1.0.0",
    };

    return data;
  }

  downloadJSONFile() {
    const data = this.buildConfig();
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(data, null, 2)], {
      type: "text/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = "smile_config.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  toggleSDK() {
    const { android } = this.state;
    this.setState({
      android: !android,
    });
  }

  render() {
    const androidLinks = [];
    const legacyLinks = [];
    const iosLinks = [];
    if (this.state.links.android) {
      const sortedLinks = semverSort3(this.state.links.android);
      sortedLinks.android.forEach((link, index) => {
        if (index === 0) {
          androidLinks.push(
            <p style={{ marginLeft: "15px" }} key="current">
              Recent version:
            </p>,
          );
        }

        const version = link.split("?")[0].match(/[0-9]+\.[0-9]+\.[0-9]+/);
        androidLinks.push(
          <a
            href={link}
            className="sdk-link"
            key={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download SmartSelfie&trade; SDK for AndroidX Version {version}
          </a>,
        );
        if (index === 0 && sortedLinks.android.length > 1) {
          androidLinks.push(
            <p style={{ marginLeft: "15px" }} key="earlier">
              Earlier versions:
            </p>,
          );
        }
      });

      sortedLinks.legacy.forEach((link, index) => {
        if (index === 0) {
          legacyLinks.push(
            <p style={{ marginLeft: "15px" }} key="current">
              Recent version:
            </p>,
          );
        }

        const version = link.split("?")[0].match(/[0-9]+\.[0-9]+\.[0-9]+/);
        legacyLinks.push(
          <a
            href={link}
            className="sdk-link"
            key={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download SmartSelfie&trade; SDK Version {version}
          </a>,
        );
        if (index === 0 && sortedLinks.legacy.length > 1) {
          legacyLinks.push(
            <p style={{ marginLeft: "15px" }} key="earlier">
              Earlier versions:
            </p>,
          );
        }
      });
    }

    if (this.state.links.ios) {
      const sortedLinks = semverSort3(this.state.links.ios);
      sortedLinks.ios.forEach((link, index) => {
        if (index === 0) {
          iosLinks.push(
            <p style={{ marginLeft: "15px" }} key="current">
              Recent version:
            </p>,
          );
        }

        const version = link.split("?")[0].match(/[0-9]+\.[0-9]+\.[0-9]+/);
        iosLinks.push(
          <a
            href={link}
            className="sdk-link"
            key={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download SmartSelfie&trade; SDK for IOS Version {version}
          </a>,
        );
        if (index === 0 && sortedLinks.ios.length > 1) {
          iosLinks.push(
            <p style={{ marginLeft: "15px" }} key="earlier">
              Earlier versions:
            </p>,
          );
        }
      });
    }

    let error = <p className="sdk--error"> </p>;
    if (this.state.error) {
      error = <p className="sdk--error failure">{this.state.error}</p>;
    } else if (!this.state.error && !this.state.encrypted_partner_id) {
      error = <p className="sdk--error">loading...</p>;
    }
    let linksSection;
    if (this.state.android) {
      linksSection = (
        <div>
          <br />
          <h2>Android v10 SDK (Latest)</h2>
          <p>
            <a
              href="https://docs.usesmileid.com/integration-options/mobile/android-v10-beta/getting-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit our documentation
            </a>{" "}
            or{" "}
            <a
              href="https://github.com/smileidentity/android"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github repo
            </a>{" "}
            for how to get started with integrating the latest Android SDK
            version in your application.
          </p>
          <h3>Requirements</h3>
          <ul>
            <li>Android 5.0+ (API level 21+)</li>
            <li>A device with Google Play Services</li>
            <li>A smile_config.json (download configuration file above)</li>
          </ul>
          <h3>Support</h3>
          <p>
            Github (
            <a
              href="https://github.com/smileidentity/android/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://github.com/smileidentity/android/
            </a>
            )
          </p>
          <hr />
          <h2>Old Android SDK (Limited support)</h2>
          <p>
            These SDKs will be retired soon with limited support. We strongly
            recommend you use the latest version of the SDKs for optimal
            performance.{" "}
          </p>
          <p>
            The recommended method of including our old SDKs in your project is
            by including it using Maven. If you are unable to do so, you can
            download the SDK using the one of the links below. Note that the
            downloaded zip will be named SmileIdentityAndroid.zip and you will
            also need to download the configuration file.{" "}
          </p>
          <p className="sdk-subtext">
            Use Android X version for Android 10 support
          </p>
          <div>{androidLinks}</div>
          <p className="sdk-emphasize sdk-margin-top">
            For development on Google API level 28 and below
          </p>
          <div>{legacyLinks}</div>
        </div>
      );
    } else {
      linksSection = (
        <div>
          <br />
          <h2>iOS v10 SDK (Latest)</h2>
          <p>
            <a
              href="https://docs.usesmileid.com/integration-options/mobile/ios-v10-beta/getting-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit our documentation
            </a>{" "}
            or{" "}
            <a
              href="https://github.com/smileidentity/iOS"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github repo
            </a>{" "}
            for how to get started with integrating the latest iOS SDK version
            in your application.
          </p>
          <h3>Requirements</h3>
          <ul>
            <li>iOS 13+</li>
            <li>Xcode 14+</li>
            <li>A smile_config.json (download configuration file above)</li>
          </ul>
          <hr />
          <h2>Old IOS SDK (Limited support)</h2>
          <p>
            These SDKs will be retired soon with limited support. We strongly
            recommend you use the latest version of the SDKs for optimal
            performance and features.
          </p>
          <p>
            The recommended method of including the IOS framework is by using
            cocoapods. If you are unable to do so, you can download the SDK
            using the one of the links below. Note that the downloaded zip will
            be named SmileIdentityIOS.zip and you will also need to download the
            configuration file.
          </p>
          <div>{iosLinks}</div>
        </div>
      );
    }

    return (
      <>
        <div className="breadcrumbs">
          <Link
            to="/partner/developer_tools"
            className="breadcrumb-link breadcrumb-link--inactive"
          >
            Developer Tools
          </Link>
          <span> &gt; </span>
          <span>SDK</span>
        </div>
        <h1>Configurations</h1>
        <div>
          To integrate our new SDKs, please refer to our{" "}
          <a
            href="https://docs.usesmileid.com/integration-options/mobile"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>{" "}
          for detailed instructions.
        </div>
        <div className="sdk-margin-top">
          {this.state.encrypted_partner_id && (
            <div className="qr-code">
              <QRCode
                value={JSON.stringify(this.buildConfig())}
                size={200}
                viewBox="0 0 200 200"
              />
            </div>
          )}
          {error}
          <button
            className="btn btn-primary"
            onClick={this.downloadJSONFile}
            disabled={!this.state.encrypted_partner_id}
          >
            Download configuration file
          </button>
        </div>

        <div className="newsmile-heading-h2 sdk-margin-top">Mobile SDKs</div>
        <div className="sdkToggle">
          <label>
            <input
              type="radio"
              onChange={this.toggleSDK}
              checked={this.state.android}
            />
            <span>Android SDK Version</span>
          </label>
          <label>
            <input
              type="radio"
              onChange={this.toggleSDK}
              checked={!this.state.android}
            />
            <span>iOS v10 SDK Version</span>
          </label>
        </div>
        {linksSection}
      </>
    );
  }
}

export default SDK;
