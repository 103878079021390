import React, { useState } from "react";
import { downloadJobResultSummaryPDF } from "util/api_util";
import { JobTypes } from "util/constants";

function PrintPdfButton({ resultPayload }) {
  const [isDisabled, setDisabled] = useState(false);

  const handleClick = async () => {
    const { smile_job_id, smile_partner_id } = resultPayload;

    setDisabled(true);

    const blob = await downloadJobResultSummaryPDF(
      smile_job_id,
      smile_partner_id,
    );

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `job-result-summary-${smile_job_id}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDisabled(false);
  };

  if (isDisabled) {
    return (
      <button
        className="btn btn-secondary print__button"
        type="button"
        style={{ marginBottom: "1rem" }}
        disabled
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        &nbsp;Downloading PDF...&nbsp;
      </button>
    );
  }

  return (
    <button
      className="btn btn-secondary print__button"
      type="button"
      onClick={handleClick}
      style={{
        marginBottom:
          resultPayload.ran_as_job_type === JobTypes.BUSINESS_VERIFICATION
            ? "1rem"
            : "0",
      }}
    >
      Print PDF
    </button>
  );
}

export default PrintPdfButton;
