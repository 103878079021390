import React from "react";
import classNames from "classnames";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";
import { ReviewGenderInput, ReviewInput } from "../ReviewFormControls";

const TextualComparisonRow = ({
  name,
  textualComparisons,
  completed,
  handleChange,
}) => {
  const formatHelper = ["dob", "expiration_date", "issuance_date"].includes(
    name,
  )
    ? "(YYYY-MM-DD)"
    : "";

  const data = textualComparisons[name];

  const { value, key } = data;
  const label = key?.replaceAll("_", " ").toUpperCase();
  let inputValue = value;

  if (["country", "id_type"].includes(key) || completed) {
    if (key === "country") {
      inputValue = ISOCountryCodeToFullNameMap[value] || value;
    }
  }

  if (name === "gender") {
    return (
      <ReviewGenderInput
        initialData={value}
        disabled={completed}
        label="Gender"
        name={name}
        onChange={handleChange}
      />
    );
  }
  const isHiddenField = () =>
    name?.includes("phone_number") ||
    ["address", "country", "id_type"].includes(name);

  return (
    <ReviewInput
      label={`${label} ${formatHelper}`}
      name={name}
      key={name}
      disabled={completed}
      type="text"
      className={classNames({ hidden: isHiddenField() })}
      onChange={handleChange}
      initialData={inputValue === "Not Available" ? "" : inputValue}
    />
  );
};

export default TextualComparisonRow;
