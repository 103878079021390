import React, { useState } from "react";
import moment from "moment";
import fileTextIcon from "resources/img/icons/file-text.svg";
import newsPaperIcon from "resources/img/icons/newspaper.svg";
import personDottedCircleIcon from "resources/img/icons/person-circle-dotted.svg";
import personCircleIcon from "resources/img/icons/person-circle.svg";
import protectionIcon from "resources/img/icons/protection.svg";
import styles from "./aml_person_found.module.css";

import Associations from "./associations";
import Enforcement from "./enforcement";
import Media from "./media";
import Pep from "./pep";
import Sanctions from "./sanctions";
import Summary from "./summary";

function AmlPersonFound({ person }) {
  const minimumItemsToShow = {
    addresses: 2,
    aliases: 10,
  };
  const [addressesToShow, setAddressesToShow] = useState(
    minimumItemsToShow.addresses,
  );
  const [aliasesToShow, setAliasesToShow] = useState(
    minimumItemsToShow.aliases,
  );

  const toggleAddressesToShow = () => {
    if (addressesToShow === person.addresses.length) {
      setAddressesToShow(minimumItemsToShow.addresses);
    } else {
      setAddressesToShow(person.addresses.length);
    }
  };

  const toggleAliasesToShow = () => {
    if (aliasesToShow === person.aliases.length) {
      setAliasesToShow(minimumItemsToShow.aliases);
    } else {
      setAliasesToShow(person.aliases.length);
    }
  };

  const showMore = (totalItems, itemsToShow, minimumItemsToShow, onToggle) =>
    totalItems.length > minimumItemsToShow && (
      <button onClick={onToggle} className={styles.showMore}>
        {itemsToShow === totalItems.length
          ? "Click for Less"
          : "Click for more"}
      </button>
    );

  return (
    <div className={styles.container}>
      <div>
        <h2>{person.name}</h2>
        <div className={styles.aliasesContainer}>
          <h4>Aliases</h4>
          <div className={styles.aliases}>
            <ul>
              {person.aliases.slice(0, aliasesToShow).map((alias) => (
                <li className={styles.border}>{alias}</li>
              ))}
            </ul>
            {showMore(
              person.aliases,
              aliasesToShow,
              minimumItemsToShow.aliases,
              toggleAliasesToShow,
            )}
          </div>
        </div>

        <div className={styles.details}>
          <div className={styles.information}>
            <h2>Information</h2>
            <div className={styles.datesOfBirth}>
              <h6> Dates of Birth </h6>
              {person.dates_of_birth.length > 0 ? (
                <ul>
                  {person.dates_of_birth.map((dob) => (
                    <li>{moment(dob).format("DD MMMM, YYYY")}</li>
                  ))}
                </ul>
              ) : (
                "N/A"
              )}
            </div>
            <div>
              <h6>Nationalities</h6>
              <ul>
                {person.nationalities?.map((nationality) => (
                  <li>{nationality}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className={styles.addresses}>
            <header>
              <h6> Addresses </h6>
              <span> {person.addresses.length} </span>
            </header>
            {person.addresses.slice(0, addressesToShow).map((address) => (
              <h3>{address}</h3>
            ))}

            {showMore(
              person.addresses,
              addressesToShow,
              minimumItemsToShow.addresses,
              toggleAddressesToShow,
            )}
          </div>
        </div>
      </div>

      <div className="stack">
        <Summary
          icon={fileTextIcon}
          title="Sanctions"
          noHits={!person.sanctions?.length}
        >
          <Sanctions sanctions={person.sanctions} />
        </Summary>
        <Summary
          icon={protectionIcon}
          title="Enforcement Action"
          noHits={!person.enforcement_actions?.length}
        >
          <Enforcement enforcementActions={person.enforcement_actions} />
        </Summary>
        <Summary
          icon={personCircleIcon}
          title="PEP"
          noHits={!person.pep?.political_positions?.length}
        >
          <Pep pep={person.pep} />
        </Summary>
        <Summary
          icon={personDottedCircleIcon}
          title="Associations"
          noHits={!person.associations?.length}
        >
          <Associations associations={person.associations} />
        </Summary>
        <Summary
          icon={newsPaperIcon}
          title="Adverse Media Publication"
          noHits={!person.adverse_media?.length}
        >
          <Media posts={person.adverse_media} />
        </Summary>
      </div>
    </div>
  );
}

export default AmlPersonFound;
