import React from "react";
import styles from "./summary.module.css";

function Summary({ icon, title, children, noHits }) {
  if (noHits) {
    return (
      <div className={styles.noHits}>
        <div className={styles.imageContainer}>
          <img src={icon} alt="" />
          <h3>{title}</h3>
        </div>
        <h4> no hits </h4>
      </div>
    );
  }

  return (
    <details className={styles.details}>
      <summary className={styles.summary}>
        <img src={icon} alt="" />
        <h3>{title}</h3>
      </summary>
      <div className="box">{children}</div>
    </details>
  );
}

export default Summary;
