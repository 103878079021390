import React, { Component } from "react";
import errorWarning from "resources/img/icons/error-warning.svg";
import { fetchEnrolledUser, fetchUsersJobs } from "util/api_util";
import { formatDashboardJobs, getUserType } from "util/selectors";
import JobResultCard from "./kyc_card";

class KYC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      resultCodes: null,
      error: null,
      message: null,
      userType: null,
      userId: null,
      environment: null,
      partnerId: null,
      user: null,
      selfie: null,
      actions: null,
      id_checks: null,
      e_signatures: null,
      original_id_cards: null,
      jobs: [],
      jobListJobs: [],
      smile_secure_enabled: false,
    };
    this.getData = this.getData.bind(this);
    this.redirectBack = this.redirectBack.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.setState(
      {
        userType: getUserType(),
        userId: params.userId,
        partnerId: params.partnerId,
        environment: params.environment,
      },
      () => {
        const { id, partnerId, environment } = params;
        if (this.state.userType === "admin") {
          sessionStorage.setItem(
            "url",
            `admin/${partnerId}/kyc/${environment}/${id}`,
          );
        } else {
          sessionStorage.setItem("url", `/partner/kyc/${environment}/${id}`);
        }

        if (this.state.environment === "production") {
          sessionStorage.setItem("displayEnvironment", "true");
        } else {
          sessionStorage.setItem("displayEnvironment", "false");
        }

        this.getData();
      },
    );
  }

  getData() {
    const params = {};
    if (this.state.userType === "admin") {
      params.partner_id = this.state.partnerId;
    }
    fetchEnrolledUser(this.state.userId, params).then((resp) => {
      this.setState({
        error: resp.error,
        user: resp.enrollee,
        jobs: resp.jobs,
        actions: resp.actions,
        selfie: resp.enrolled_image,
        e_signatures: resp.e_signatures,
        id_checks: resp.id_checks.concat(resp.documents),
        original_id_cards: resp.original_id_cards,
        smile_secure_enabled: resp.smile_secure_enabled,
      });
    });
    params.partner_uid = this.state.userId;
    fetchUsersJobs(params).then((resp) => {
      this.setState({
        error: resp.error,
        jobListJobs: formatDashboardJobs(resp.jobs, resp.result_codes),
      });
    });
  }

  redirectBack() {
    const url =
      this.state.userType === "admin"
        ? `/admin/partner_dashboards/${this.state.partnerId}/user_list`
        : "/partner/user_list";
    this.props.history.push(url);
  }

  render() {
    return (
      <>
        <div className="breadcrumbs">
          <a
            className="breadcrumb-link breadcrumb-link--inactive"
            onClick={this.redirectBack}
          >
            User List
          </a>
          <span> &gt; </span>
          <span>KYC</span>
        </div>

        {this.state.error && (
          <div className="no-info__container">
            <img className="icon--large" src={errorWarning} alt="Error" />
            <div className="no-info">{this.state.error}</div>
          </div>
        )}

        {!this.state.error &&
          (this.state.user ? (
            <JobResultCard
              jobs={this.state.jobs}
              selfie={this.state.selfie}
              user={this.state.user}
              actions={this.state.actions}
              id_checks={this.state.id_checks}
              e_signatures={this.state.e_signatures}
              original_id_cards={this.state.original_id_cards}
              jobListJobs={this.state.jobListJobs}
              history={this.props.history}
              partnerId={this.state.partnerId}
              smile_secure_enabled={this.state.smile_secure_enabled}
              userType={this.props.userType}
            />
          ) : (
            <div className="loader__container">
              <div className="loader loader--centered" />
            </div>
          ))}
      </>
    );
  }
}

export default KYC;
