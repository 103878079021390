import React, { Component } from "react";
import classNames from "classnames";
import Select from "react-select";

import { buildState, buildUrl } from "util/api_util";
import { getJobTypes } from "util/selectors";
import { REVIEWS_HEADERS } from "util/table_headers";
import styles from "./reviews.module.css";
import ReviewsShow from "./reviews_show";

const findSelectedJobType = (jobTypeValue, jobTypes) =>
  jobTypes.find((jobType) => jobType.value === jobTypeValue) || "";

class Reviews extends Component {
  constructor(props) {
    super(props);
    const query = buildState(this.props.history.location.search);
    const jobTypes = getJobTypes();
    const selectedJobType = findSelectedJobType(query.job_type, jobTypes);

    this.state = {
      displayEnvironment: window.store.getState().displayEnvironment,
      selectedJobType,
      query,
      jobTypes,
    };

    this.fetchReviews = this.fetchReviews.bind(this);
    this.onInput = this.onInput.bind(this);
  }

  componentWillUnmount() {
    this.props.removeReviewsState();
  }

  componentDidUpdate(props) {
    if (props.displayEnvironment !== this.props.displayEnvironment) {
      this.fetchReviews();
    }
  }

  fetchReviews() {
    const queryState = buildState(this.props.history.location.search); // access query params
    return this.props.fetchResources("reviews", queryState);
  }

  onInput(field) {
    return (option) => {
      if (field === "job_type") {
        this.setState({
          selectedJobType: option,
        });
      }

      this.setState(
        {
          query: {
            ...this.state.query,
            [field]: option.value,
          },
        },
        () => {
          this.props.history.push(
            `/admin${buildUrl("reviews", this.state.query)}`,
          );
          this.fetchReviews();
        },
      );
    };
  }

  render() {
    const { history, reviews, userType, partners } = this.props;
    const { jobTypes, query, selectedJobType } = this.state;
    let partnerSelector;
    if (userType === "admin") {
      const partnerOptions = partners.map((partner) => ({
        value: partner.split(" - ")[0],
        label: partner,
      }));

      const selectedPartner = partnerOptions.find(
        (option) => option.value === query.partner_id,
      );

      partnerSelector = (
        <div className={styles.filtersContainer}>
          <label className="head-link inline">Filter by</label>
          <Select
            value={selectedPartner}
            onChange={this.onInput("partner_id")}
            options={partnerOptions}
            styles={{
              option: (provided) => ({ ...provided, color: "black" }),
            }}
            isClearable
            isLoading={partnerOptions.length === 0}
            placeholder="All Partners"
            isSearchable
          />
          <Select
            defaultValue={selectedJobType}
            onChange={this.onInput("job_type")}
            options={jobTypes}
            styles={{
              option: (provided) => ({ ...provided, color: "black" }),
            }}
            isClearable
            isLoading={jobTypes.length === 0}
            placeholder="All Job Types"
            isSearchable
          />
        </div>
      );
    }

    return (
      <div
        className={classNames(
          "newsmile-container__main--temporarily-white dark_bg theme-dark",
        )}
      >
        <div className={styles.partnerSelect}>
          {partnerSelector}
          <ReviewsShow
            data={reviews}
            headers={REVIEWS_HEADERS}
            reviews
            tableType="reviews"
            history={history}
            fetchReviews={this.fetchReviews}
          />
        </div>
      </div>
    );
  }
}

export default Reviews;
