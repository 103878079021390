import React, { useEffect, useState } from "react";

import Select, { components } from "react-select";
import DropdownArrow from "resources/img/icons/dropdown_arrow.svg";
import styles from "./custom_select.module.css";

/**
 * The is used to style the various react-select components used by the dropdown component
 */
const selectStyles = {
  option: (defaultStyles, { isFocused }) => ({
    ...defaultStyles,
    backgroundColor: isFocused ? defaultStyles.backgroundColor : "white",
    color: isFocused ? defaultStyles.color : "#151F72",
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    minWidth: 240,
    border: "0.625px solid #9394AB",
    borderRadius: 4,
    boxSizing: "border-box",
    height: 20,
    alignContent: "center",
    margin: 8,
    fontSize: 13,
  }),
  menu: () => ({
    backgroundColor: "white",
    fontSize: 13,
    minWidth: 240,
    color: "#151F72",
    fontWeight: 600,
  }),
};

/**
 * A dropdown which has a custom implementation of react-select
 * @param   {object}  params              props
 * @param   {Array}    params.options          The options to show in the dropdown, must be an array containing object
 * @param   {any}    params.options.label    The label to be used displayed
 * @param   {any}    params.options.value    The value to be used
 * @param   {string}  params.placeholder       The placeholder text to show when nothing is selected, also added to the dropdown
 * when the user has to reset to default empty selection
 * @param {object}    params.props         any extra props that react-select supports
 * @returns  {Element}                       a dropdown element
 */
export default function CustomSelect({ options, placeholder, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(props.value);

  const onClose = () => {
    setIsOpen(false);
    props.onChange(value);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  if (!props.isMulti && props.hasEmpty) {
    options = [{ label: placeholder, value: "" }, ...options];
  }

  const onChange = (newValue) => {
    setValue(newValue);
    if (!props.isMulti) {
      setIsOpen(false);
      props.onChange(newValue);
    }
  };

  // used to replace react-select components with custom ones
  const components = {
    DropdownIndicator: null,
    IndicatorSeparator: null,
    Option,
    MenuList,
  };
  if (!props.isSearchable) {
    components.Control = Empty;
  }

  return (
    <MultiSelectDropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <button
          type="button"
          className={`${styles.dd_header} ${styles.dropshadow}`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className={styles.title_content}>
            <SelectedValue value={value} placeholder={placeholder} {...props} />
            <img className={styles.angle_down} src={DropdownArrow} alt="" />
          </div>
        </button>
      }
    >
      <Select
        isMulti={props.isMulti}
        // autoFocus
        backspaceRemovesValue={false}
        captureMenuScroll={false}
        {...props}
        components={components}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        menuShouldScrollIntoView={false}
        closeMenuOnSelect={false}
        onChange={onChange}
        onConfirm={onClose}
        options={options}
        placeholder=""
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
      />
    </MultiSelectDropdown>
  );
}

// styled components

/**
 * This is used to show the selected value(s), if no item is selected, the placeholder is displayed
 * @param {object}      params         params
 * @param {object|Array}   params.value     The selected object. This can be an array if isMulti is true
 * @param {string}       params.placeholder   A placeholder text to display when there is no selection
 * @returns {Element}              An element is returned
 */
function SelectedValue({ value, placeholder, ...props }) {
  let label = value?.label;

  if (props.isMulti && value?.length > 0) {
    // we display the label when a single value is selected
    // and display the first selected label and the count of the remainer
    label = value[0].label;
    if (value.length > 1) {
      label = `${label} (${value.length - 1} more)`;
    }
  }

  return <div className={styles.dd_header_title}>{label || placeholder}</div>;
}

function Menu(props) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: "0px 4px 10px 1px rgba(105, 204, 250, 0.25)",
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
}

// Inner wrapper for the menu. It directly wraps around the returned options.
// We customise this to add a filter button at the button of the dropdown list
function MenuList(props) {
  const onConfirm = () => {
    props.selectProps.onConfirm(true);
  };

  return (
    <components.MenuList {...props}>
      {props.children}
      {props.isMulti ? (
        <div className={styles.dd_list_button_item}>
          <button
            type="button"
            className={styles.dd_filter_button}
            onClick={onConfirm}
          >
            Filter
          </button>
        </div>
      ) : null}
    </components.MenuList>
  );
}

/**
 * used to replace elements that we wish to remove from react-select
 * @returns {Element} a span element
 */
function Empty() {
  return <span />;
}

function Blanket(props) {
  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: "fixed",
        zIndex: 1,
      }}
      {...props}
    />
  );
}

/**
 * Dropdown wrapper
 * @param   {Map} options required params for the dropdown
 * @param   {Array<Element>}  options.children  menu elements
 * @param   {boolean}  options.isOpen    state of the dropdown
 * @param   {Element}  options.target    the main element to display that triggers the dropdown
 * @param   {Function}  options.onClose   a callback function to run when the dropdown is closed
 * @returns  {Element}            jsx element
 */
function MultiSelectDropdown({ children, isOpen, target, onClose }) {
  return (
    <div className={styles.dd_wrapper}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
}

/**
 * Component responsible for displaying an option in the dropdown menu.
 * @param   {object}  props  options props supported by react-select
 * https://react-select.com/components
 * @returns  {Element}         jsx element
 */
function Option(props) {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;
  const customStyles = props.isMulti
    ? { display: "flex", padding: "0 10" }
    : {};
  const customClassNames = [styles.dd_list_item, styles.checkbox_container];
  return (
    <div
      ref={innerRef}
      style={{
        ...getStyles("option", props),
        padding: "12px 12px",
        ...customStyles,
      }}
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className,
        ...customClassNames,
      )}
      {...innerProps}
    >
      {props.isMulti ? (
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isSelected}
          readOnly
        />
      ) : null}
      {children}
    </div>
  );
}
