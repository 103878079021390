import React from "react";
import KYCForm from "components/reusable/kyc_form";

function KYCReview({ match }) {
  const partnerID = match.params.partner_id;

  return <KYCForm partnerID={partnerID} hideNav />;
}

export default KYCReview;
