import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";

import { Link } from "react-router-dom";
import IconCopy from "resources/img/icons/copy-new.svg";
import IconDocsCTA from "resources/img/icons/docs-cta.png";
import IconApproved from "resources/img/icons/green-check.svg";
import IconOpenInNewTab from "resources/img/icons/open-in-new-tab.svg";
import IconWarn from "resources/img/icons/yellow-warning.svg";
import { fetchAPIKey, generateAPIKey } from "util/api_util";
import { copy } from "util/format_helpers";
import { getAdminPartnerId, getUserType } from "util/selectors";
import ConfirmPassword from "../confirm_password";
import ErrorAndMessageBar from "../error_and_message_bar";
import Tabs from "../tabs";

class APIKey extends Component {
  constructor(props) {
    super(props);

    const user = jwtDecode(localStorage.token);
    this.state = {
      apiKey: undefined,
      apiKeyV2: undefined,
      v2: true,
      encoded: true,
      company: undefined,
      user,
      confirmPassword: false,
      disableSubmit: false,
      loading: true,
      adminPartnerId: getAdminPartnerId(),
    };

    this.newKey = this.newKey.bind(this);
    this.rollKey = this.rollKey.bind(this);
    this.copy = this.copy.bind(this);
    this.toggleBoolean = this.toggleBoolean.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      userType: getUserType(),
    });

    this.getData();
  }

  getData() {
    let partnerId;
    if (this.state.user.type === "admin" && this.state.adminPartnerId) {
      partnerId = this.state.adminPartnerId;
    }
    fetchAPIKey({ v2: this.state.v2, partnerId }).then((resp) => {
      const keyName = this.state.v2 ? "apiKeyV2" : "apiKey";
      this.setState({
        company: resp.company,
        [keyName]: resp.api_key,
        loading: false,
      });
    });
  }

  componentDidUpdate(props) {
    if (props.displayEnvironment !== this.props.displayEnvironment) {
      this.setState({
        apiKey: undefined,
        apiKeyV2: undefined,
        loading: true,
      });
      this.getData();
    }
  }

  copy() {
    let textObj;

    if (this.state.v2) {
      textObj = copy("api-key-v2", "API key copied to clipboard!");
    } else {
      textObj = copy("api-key", "API key copied to clipboard!");
    }
    this.setState(textObj);
  }

  newKey(e) {
    e.preventDefault();
    this.setState({
      disabled: true,
      confirmPassword: true,
    });
  }

  rollKey() {
    // make api call here to the backend
    generateAPIKey({ v2: this.state.v2 }).then((resp) => {
      if (this.state.v2) {
        this.setState({
          apiKeyV2: resp.api_key,
        });
      } else {
        this.setState({
          apiKey: resp.api_key,
        });
      }

      this.setState({
        disabled: false,
        confirmPassword: false,
        error: resp.error,
      });
      if (resp.error) {
        let partnerId;
        if (this.state.user.type === "admin" && this.state.adminPartnerId) {
          partnerId = this.state.adminPartnerId;
        }
        fetchAPIKey({ v2: this.state.v2, partnerId }).then((resp) => {
          if (this.state.v2) {
            this.setState({
              company: resp.company,
              apiKeyV2: resp.api_key,
            });
          } else {
            this.setState({
              company: resp.company,
              apiKey: resp.api_key,
            });
          }
        });
      }
    });
  }

  toggleBoolean(key) {
    const old = this.state[key];
    this.setState(
      {
        [key]: !old,
      },
      () => {
        if (key === "v2") {
          this.getData();
        }
      },
    );
  }

  renderV1(apiKey, pemKey) {
    return (
      <div className="flow">
        <div className="highlight">
          <p>
            The Encoded Key is the default Key to generate your Signature. If
            you intend to generate your Signature with the older versions of our
            Server-Side Library, you should use the Decoded Key.
          </p>
        </div>

        <fieldset
          className="input-group"
          aria-label="API version switcher"
          role="radiogroup"
        >
          <div className="input-group">
            <input
              onChange={() => this.toggleBoolean("encoded")}
              type="radio"
              name="format"
              id="encoded"
              checked={this.state.encoded}
            />
            <label htmlFor="encoded">Encoded Key</label>
          </div>

          <div className="input-group">
            <input
              onChange={() => this.toggleBoolean("encoded")}
              type="radio"
              name="format"
              id="decoded"
              checked={!this.state.encoded}
            />
            <label htmlFor="decoded">Decoded Key</label>
          </div>
          <legend className="visually-hidden">Change API Key Format</legend>
        </fieldset>

        {this.state.encoded && <div className="callout">{apiKey}</div>}

        {!this.state.encoded && <div className="callout">{pemKey}</div>}

        <div className="api-key__warning">
          {(this.state.message || this.state.error) && (
            <ErrorAndMessageBar
              messages={this.state.message}
              errors={this.state.error}
            />
          )}
        </div>
      </div>
    );
  }

  renderV2() {
    let keyOrMessage;
    if (!this.state.apiKeyV2 && !this.state.loading) {
      keyOrMessage = (
        <p>You have not generated an api key for this environment yet.</p>
      );
    }
    return (
      <div className="flow">
        <div className="highlight">
          <p>
            This key is for use in calculating the signature used to sign
            requests.
          </p>
        </div>

        <div className="callout">
          <div className="api-key__warning">
            {(this.state.message || this.state.error) && (
              <ErrorAndMessageBar
                messages={this.state.message}
                errors={this.state.error}
              />
            )}
          </div>
          <p className="code" id="api-key-v2">
            {this.state.apiKeyV2}
          </p>
          {keyOrMessage}
        </div>
      </div>
    );
  }

  render() {
    let apiKey;
    let pemKey = null;
    const env =
      String(this.props.displayEnvironment) === "true"
        ? "Production"
        : "Sandbox";
    const inverseEnv = env === "Production" ? "Sandbox" : "Production";
    const docsLink = this.state.v2
      ? "https://docs.usesmileid.com/rest-api/signing-your-api-request/generate-signature"
      : "https://docs.usesmileid.com/rest-api/signing-your-api-request/generate-sec-key-legacy";

    if (this.state.apiKey) {
      apiKey = (
        <p className="code" id="api-key">
          {this.state.apiKey}
        </p>
      );
      let pem;
      try {
        pem = window.atob(this.state.apiKey);
      } catch (err) {
        pem = "";
      }
      pemKey = (
        <p className="code" id="api-key">
          {pem}
        </p>
      );
    } else if (this.state.loading) {
      apiKey = (
        <div className="loader__container">
          <div className="loader loader--centered" />
        </div>
      );
    }

    let buttonsDiv;
    if (this.state.confirmPassword) {
      buttonsDiv = (
        <div>
          <h5 className="failure">
            <span className="fa fa-warning" />
            &nbsp;Warning: If you continue your old API key will no longer work.
          </h5>
          <h5 className="failure">
            Confirm password to destroy your previous key and generate a new
            one.
          </h5>
          <ConfirmPassword action={this.rollKey} />
        </div>
      );
    } else if (this.state.userType === "partner") {
      buttonsDiv = (
        <button
          className="btn btn-primary"
          onClick={this.newKey}
          disabled={this.state.disabled}
        >
          Generate New API Key
        </button>
      );
    }

    return (
      <div className="api-key flow">
        <div className="breadcrumbs">
          <Link
            to="/partner/developer_tools"
            className="breadcrumb-link breadcrumb-link--inactive"
          >
            Developer Tools
          </Link>
          <span> &gt; </span>
          <span>
            API Key{" "}
            {this.state.company && (
              <span>
                for{" "}
                <span className="api-key__company-name">
                  {this.state.company.name}
                </span>
              </span>
            )}{" "}
          </span>
        </div>

        <div className="callout callout--small">
          <img
            src={env === "Production" ? IconApproved : IconWarn}
            height="14px"
            width="14px"
          />
          <p>
            This is your {env} API Key and&nbsp;
            <span
              className={env === "Production" ? "color-approved" : "color-warn"}
            >
              can only be used
            </span>
            &nbsp;in {env}. For {inverseEnv} API key, switch to {inverseEnv}.
          </p>
        </div>

        <Tabs
          labels={["Signature", "Sec Key (Legacy)"]}
          onTabChange={() => {
            this.setState({ message: null });
            this.toggleBoolean("v2");
          }}
        >
          {this.renderV2()}
          {this.renderV1(apiKey, pemKey)}
        </Tabs>

        <button
          style={{ "--flow-space": "2rem" }}
          className="btn btn--link btn-with-icon copy-btn"
          type="button"
          onClick={this.copy}
        >
          <img src={IconCopy} height="14" width="14" alt="" />
          <span>Copy {env} API Key</span>
        </button>

        {buttonsDiv}

        <div
          className="callout callout--card"
          style={{ "--flow-space": "8rem" }}
        >
          <img
            src={IconDocsCTA}
            height="50"
            width="50"
            style={{ borderRadius: "50%" }}
          />
          <div className="flow">
            <h2>Explore API Documentation</h2>
            <p>
              Need help with your integration?
              <br />
              Check out our API Documentation
            </p>
            <a
              href={docsLink}
              className="btn btn--link btn-with-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconOpenInNewTab} height="14" width="14" />
              <span>Visit Docs</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default APIKey;
