import React, { Component } from "react";
import { connect } from "react-redux";

import Card from "components/reusable/card";
import DemoAppIcon from "resources/img/brand/smile-id__symbol--96x96.svg";
import LibraryIcon from "resources/img/icons/books--colored.svg";
import DocsIcon from "resources/img/icons/page--colored.svg";
import WarningIcon from "resources/img/icons/warning.svg";
import CloseIcon from "resources/img/icons/x.svg";
import { editProducts, fetchSDKLink } from "util/api_util";
import {
  findIdValidationProduct,
  isIdValidationProductValidated,
} from "util/product_helpers";
import { getAdminPartnerId, getUserType, semverSort3 } from "util/selectors";

class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerInfo: null,
      products: null,
      idValidationProduct: null,
      idIsValidated: false,
      hasAlreadyEnabled: false,
      loading: false,
      demoLinkLoading: false,

      modalIsOpen: false,
      countries: {
        Ghana: false,
        Kenya: false,
        Nigeria: false,
        Tanzania: false,
      },
      terms: {
        Ghana: false,
        Kenya: false,
        Nigeria: false,
        Tanzania: false,
      },
      hasASelectedCountry: false,
      checkedRelevantTerms: false,

      modalStep1: true,
      modalStep2: false,
      modalStep3: false,

      latestAndroidlink: null,
      demoLink: null,

      adminPartnerId: getAdminPartnerId(),
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);
    this.toggleTermsCheck = this.toggleTermsCheck.bind(this);
    this.onTsAndCsFormSubmit = this.onTsAndCsFormSubmit.bind(this);
    this.onModalNavigation = this.onModalNavigation.bind(this);
    this.processComplete = this.processComplete.bind(this);
    this.redirectTo = this.redirectTo.bind(this);

    this.hasASelectedCountry = this.hasASelectedCountry.bind(this);
    this.checkedRelevantTerms = this.checkedRelevantTerms.bind(this);
  }

  componentDidMount() {
    sessionStorage.setItem("url", `/partner/developer_tools`);

    this.setState(
      {
        userType: getUserType(),
      },
      () => {
        this.setPartnerInfo();
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partnerInfo !== this.props.partnerInfo) {
      this.setPartnerInfo();
    }
  }

  setPartnerInfo() {
    this.setState(
      {
        partnerInfo: this.props.partnerInfo,
        products: this.props.partnerInfo.products,
        idValidationProduct: this.getIdValidationProduct({
          partner: this.props.partnerInfo,
        }),
      },
      () => {
        this.setInitialState();
      },
    );
  }

  setInitialState() {
    if (this.state.idValidationProduct) {
      this.setInitialCountriesState();
      this.setInitialTermsState();
    }
  }

  setInitialCountriesState() {
    const updatedCountries = {};
    for (const country in this.state.countries) {
      if (this.state.idValidationProduct.id_validation[country]) {
        const { included } =
          this.state.idValidationProduct.id_validation[country];
        updatedCountries[country] = included;
        if (included) {
          this.setState({
            hasASelectedCountry: true,
            hasAlreadyEnabled: true,
          });
        }
      } else {
        updatedCountries[country] = false;
      }
    }

    this.setState({
      countries: updatedCountries,
    });
  }

  setInitialTermsState() {
    if (this.state.partnerInfo.company.accepted_terms_and_conditions) {
      const updatedTerms = {};
      for (const countryTerms in this.state.terms) {
        if (
          this.state.partnerInfo.company.accepted_terms_and_conditions[
            countryTerms
          ]
        ) {
          updatedTerms[countryTerms] = true;
        } else {
          updatedTerms[countryTerms] = false;
        }
      }
      this.setState({
        terms: updatedTerms,
        checkedRelevantTerms: this.checkedRelevantTerms(),
      });
    }
  }

  getSDKLink = () => {
    if (this.state.userType !== "partner") {
      return;
    }
    const newTab = window.open("", "_blank");
    newTab.document.body.innerHTML = "loading...";
    this.setState({ demoLinkLoading: true });
    fetchSDKLink().then((resp) => {
      newTab.location.href = resp.sdk_links.demo[0];
      newTab.document.title = "done";
      this.setState({
        latestAndroidlink: semverSort3(resp.sdk_links.android).legacy[0],
        demoLink: resp.sdk_links.demo[0],
        demoLinkLoading: false,
      });
    });
  };

  getIdValidationProduct(resp) {
    const idProduct = findIdValidationProduct(resp);
    this.setState({
      idIsValidated: isIdValidationProductValidated(idProduct),
    });
    return idProduct;
  }

  onTsAndCsFormSubmit() {
    this.setState({ loading: true });
    const { products, idValidationProduct, partnerInfo } = this.state;

    // fix the mutation here
    this.amendIdValidationConfig();

    const idValidationProductIndex = products.findIndex(
      (o) => o.sku === "1-IDVALIDATION",
    );
    products[idValidationProductIndex] = idValidationProduct;
    // fix mutation of products

    const data = {
      partner_id: partnerInfo.smile_partner_id,
      products,
      ts_and_cs: this.setTermsAndConditionsTime(),
    };

    editProducts(data).then((resp) => {
      this.setState({
        loading: false,
      });
      if (!resp.error) {
        this.onModalNavigation(false, true, false);
      }
    });
  }

  onModalNavigation(bool1, bool2, bool3) {
    this.setState({
      modalStep1: bool1,
      modalStep2: bool2,
      modalStep3: bool3,
    });
  }

  setTermsAndConditionsTime() {
    const { terms } = this.state;

    const updatedTerms = {};
    for (const key in terms) {
      if (terms[key]) {
        updatedTerms[key] = new Date();
      }
    }

    return updatedTerms;
  }

  amendIdValidationConfig() {
    // fix the mutation here
    const { countries, idValidationProduct } = this.state;

    for (const country in countries) {
      if (idValidationProduct.id_validation[country]) {
        idValidationProduct.id_validation[country].included =
          countries[country];
      }
    }
    return idValidationProduct;
  }

  hasASelectedCountry() {
    return Object.values(this.state.countries).includes(true);
  }

  toggleModal() {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    });
    // If we want it to start from the start again just enable below
    // this.onModalNavigation(true, false, false);
  }

  toggleCheck(e) {
    const { countries } = this.state;
    countries[e.target.name] = e.target.checked;

    if (!e.target.checked) {
      this.uncheckAssociatedTerms(e.target.name, e.target.checked);
    }

    this.setState({
      countries,
      hasASelectedCountry: this.hasASelectedCountry(),
      checkedRelevantTerms: this.checkedRelevantTerms(),
    });

    this.hasASelectedCountry();
  }

  uncheckAssociatedTerms(name, checked) {
    const { terms } = this.state;

    terms[name] = checked;
    this.setState({
      terms,
    });
  }

  toggleTermsCheck(e) {
    const { terms } = this.state;

    terms[e.target.name] = e.target.checked;
    this.setState({
      terms,
      checkedRelevantTerms: this.checkedRelevantTerms(),
    });
  }

  checkedRelevantTerms() {
    for (const key of Object.keys(this.state.countries)) {
      if (this.state.countries[key] !== this.state.terms[key]) {
        return false;
      }
    }
    return true;
  }

  processComplete() {
    this.setState({
      hasAlreadyEnabled: true,
    });
    this.toggleModal();
  }

  setAdminPartnerParam(params, key) {
    params[key] = this.state.adminPartnerId;
  }

  redirectTo(event) {
    sessionStorage.setItem("url", event.target.dataset.value);
    this.props.history.push(event.target.dataset.value);
  }

  render() {
    const env = { false: "sandbox", true: "production" }[
      this.props.displayEnvironment
    ];

    return (
      <>
        <div className="smile-card__container get-started__card-container">
          <Card
            cardClass="smile-card--centered"
            heading="Demo App"
            icon={DemoAppIcon}
            text="Try out enrollment and authentication using our demo app."
            buttonText="Download"
            openInNewTab
            href={this.state.demoLink}
            onCardClick={this.getSDKLink}
            disabled={
              this.state.userType !== "partner" || this.state.demoLinkLoading
            }
          />

          <Card
            cardClass="smile-card--centered"
            heading="Mobile SDKs"
            icon={LibraryIcon}
            text="Browse SDK versions, Download and Integrate."
            buttonText="Browse"
            openInNewTab={false}
            href="/sdk"
            disabled={
              this.state.userType !== "partner" &&
              this.state.userType !== "admin"
            }
          />

          <Card
            cardClass="smile-card--centered"
            heading="Guides and Documentation"
            icon={DocsIcon}
            text="Check out our quickstart guide, faq's and documentation."
            buttonText="View"
            openInNewTab
            href="https://docs.usesmileid.com"
          />

          <Card
            cardClass="smile-card--centered"
            heading="API Key"
            icon={DocsIcon}
            text="View and manage your API keys."
            buttonText="View"
            openInNewTab={false}
            href="/api-key"
          />

          <Card
            cardClass="smile-card--centered"
            heading="Default Callback URL"
            icon={DocsIcon}
            text="Set or update default callback URL."
            buttonText="Set"
            openInNewTab={false}
            href="/callback-url"
          />

          <Card
            cardClass="smile-card--centered"
            heading="Smile Links"
            icon={DocsIcon}
            text="Create a verification link in less than 5 minutes without code."
            buttonText="View"
            openInNewTab={false}
            href={`${env}/smile_links`}
          />

          {this.state.loading ? (
            <div className="loader__container get_started__loader-container">
              <div className="loader loading-data-spinner get_started__loader" />
            </div>
          ) : (
            this.state.idValidationProduct &&
            !this.state.idIsValidated && (
              <div>
                {this.state.hasAlreadyEnabled ? (
                  <Card
                    cardClass="smile-card--centered card__developer-tools-id-validation"
                    heading="ID Validation"
                    icon={WarningIcon}
                    text="Validate your identity using the Smile ID Demo App to start using the product. "
                    buttonText="View your existing configuration"
                    openInNewTab={false}
                    onCardClick={this.toggleModal}
                  />
                ) : (
                  <Card
                    cardClass="smile-card--centered card__developer-tools-id-validation"
                    heading="Enable ID Validation"
                    icon={WarningIcon}
                    text="Configure your product to start using it."
                    buttonText="Configure"
                    openInNewTab={false}
                    onCardClick={this.toggleModal}
                  />
                )}
              </div>
            )
          )}
        </div>

        <div
          style={{
            display: this.state.modalIsOpen ? "block" : "none",
          }}
          className="modal-backdrop"
        >
          <div className="modal-style smile-modal get-started__modal">
            <div className="button-container--right smile-modal__close">
              <img
                className="icon--medium"
                src={CloseIcon}
                onClick={this.toggleModal}
                alt="close-icon"
              />
            </div>

            <div
              className="get-started__modal-content"
              style={{
                display: this.state.modalStep1 ? "block" : "none",
              }}
            >
              <h2 className="get-started__header">Enable ID Validation</h2>
              <div className="get-started__sub-header">
                You will be need to choose the countries for which you will
                require identity validation
              </div>

              <form className="get-started__country-selection">
                <div className="get-started__country-selection-header">
                  1. Select countries for ID validation
                </div>

                <div className="field smile-field--checkbox">
                  <input
                    type="checkbox"
                    name="Nigeria"
                    checked={this.state.countries.Nigeria}
                    onClick={this.toggleCheck}
                  />
                  <label className="label--checkbox">Nigeria</label>
                </div>
                <div className="field smile-field--checkbox">
                  <input
                    type="checkbox"
                    name="Ghana"
                    checked={this.state.countries.Ghana}
                    onClick={this.toggleCheck}
                  />
                  <label className="label--checkbox">Ghana</label>
                </div>
                <div className="field smile-field--checkbox">
                  <input
                    type="checkbox"
                    name="Kenya"
                    checked={this.state.countries.Kenya}
                    onClick={this.toggleCheck}
                  />
                  <label className="label--checkbox">Kenya</label>
                </div>
                <div className="field smile-field--checkbox">
                  <input
                    type="checkbox"
                    name="Tanzania"
                    checked={this.state.countries.Tanzania}
                    onClick={this.toggleCheck}
                  />
                  <label className="label--checkbox">Tanzania</label>
                </div>

                {this.state.hasASelectedCountry && (
                  <div>
                    <div className="get-started__country-selection-header">
                      2. Accept the terms and conditions
                    </div>

                    {this.state.countries.Nigeria && (
                      <div className="field smile-field--checkbox">
                        <input
                          type="checkbox"
                          name="Nigeria"
                          checked={this.state.terms.Nigeria}
                          onClick={this.toggleTermsCheck}
                        />
                        <label className="label--checkbox">
                          I have read and I accept the{" "}
                          <a
                            className="get-started__terms-link"
                            href="https://usesmileid.com/legal/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </a>{" "}
                          for ID validation within Nigeria
                        </label>
                      </div>
                    )}

                    {this.state.countries.Ghana && (
                      <div className="field smile-field--checkbox">
                        <input
                          type="checkbox"
                          name="Ghana"
                          checked={this.state.terms.Ghana}
                          onClick={this.toggleTermsCheck}
                        />
                        <label className="label--checkbox">
                          I have read and I accept the{" "}
                          <a
                            className="get-started__terms-link"
                            href="https://usesmileid.com/legal/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </a>{" "}
                          for ID validation within Ghana
                        </label>
                      </div>
                    )}

                    {this.state.countries.Kenya && (
                      <div className="field smile-field--checkbox">
                        <input
                          type="checkbox"
                          name="Kenya"
                          checked={this.state.terms.Kenya}
                          onClick={this.toggleTermsCheck}
                        />
                        <label className="label--checkbox">
                          I have read and I accept the{" "}
                          <a
                            className="get-started__terms-link"
                            href="https://usesmileid.com/legal/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </a>{" "}
                          for ID validation within Kenya
                        </label>
                      </div>
                    )}

                    {this.state.countries.Tanzania && (
                      <div className="field smile-field--checkbox">
                        <input
                          type="checkbox"
                          name="Tanzania"
                          checked={this.state.terms.Tanzania}
                          onClick={this.toggleTermsCheck}
                        />
                        <label className="label--checkbox">
                          I have read and I accept the{" "}
                          <a
                            className="get-started__terms-link"
                            href="https://usesmileid.com/legal/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </a>{" "}
                          for ID validation Tanzania
                        </label>
                      </div>
                    )}
                  </div>
                )}

                <div className="dt-id-selection__button-container button-container--center">
                  <button
                    className={`btn btn-primary ${
                      !this.state.checkedRelevantTerms && "disabled"
                    }`}
                    onClick={this.onTsAndCsFormSubmit}
                    type="button"
                  >
                    {this.state.loading ? "Loading..." : "Next"}
                  </button>
                </div>

                <div className="modal__dots-container">
                  <div className="modal__dot modal__dot--active" />
                  <div className="modal__dot" />
                  <div className="modal__dot" />
                </div>
              </form>
            </div>

            <div
              className="get-started__modal-content"
              style={{
                display: this.state.modalStep2 ? "block" : "none",
              }}
            >
              <h2 className="get-started__header">Activate ID Validation</h2>
              <div className="get-started__sub-header">
                To use this feature you will need to first complete your own
                identity validation
              </div>

              <div className="download-demo-app__activation-container">
                <div className="download-demo-app__image-container">
                  <img
                    className="icon--large"
                    src={DemoAppIcon}
                    alt="demo-app-icon"
                  />
                </div>
                <div className="download-demo-app__text-container">
                  <div className="download-demo-app__header">
                    Download the Demo App
                  </div>
                  <div>
                    You will need to validate your ID using the Demo App to
                    start using the product
                  </div>
                </div>
                <div className="download-demo-app__button-container">
                  <a
                    className="btn-href download-demo-app__button"
                    href={this.state.demoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn btn-primary" type="button">
                      Download
                    </button>
                  </a>
                </div>
              </div>

              <div className="download-demo-app__already-have">
                Already have the app downloaded? Click next
              </div>

              <div className="modal__button-container button-container--center">
                <button
                  type="button"
                  className="modal__button btn btn-white"
                  onClick={() => this.onModalNavigation(true, false, false)}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="modal__button btn-primary"
                  onClick={() => this.onModalNavigation(false, false, true)}
                >
                  Next
                </button>
              </div>

              <div className="modal__dots-container">
                <div className="modal__dot" />
                <div className="modal__dot modal__dot--active" />
                <div className="modal__dot" />
              </div>
            </div>

            <div
              className="get-started__modal-content"
              style={{
                display: this.state.modalStep3 ? "block" : "none",
              }}
            >
              <h2 className="get-started__header">Activate ID Validation</h2>
              <div className="get-started__sub-header">
                To use this feature you will need to first complete your own
                identity validation
              </div>

              <div className="dt-id-activation__steps-container">
                <div className="dt-id-activation__steps-header">
                  Follow these steps in the demo app:
                </div>
                <div className="dt-id-activation__step">
                  <div className="dt-id-activation__step-number">1</div>
                  <span>
                    Have one of these forms of ID ready: Nigerian BVN, Nigerian
                    NIN
                  </span>
                </div>
                <div className="dt-id-activation__step">
                  <div className="dt-id-activation__step-number">2</div>
                  <span>Enter your Smile ID developer email address</span>
                </div>
                <div className="dt-id-activation__step">
                  <div className="dt-id-activation__step-number">3</div>
                  <span>Select the ID Validation product</span>
                </div>
                <div className="dt-id-activation__step">
                  <div className="dt-id-activation__step-number">4</div>
                  <span>
                    Follow the screen prompts to validate your identity
                  </span>
                </div>
              </div>

              <div className="modal__button-container button-container--center">
                <button
                  type="button"
                  className="modal__button btn btn-white"
                  onClick={() => this.onModalNavigation(false, true, false)}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="modal__button btn-primary"
                  onClick={this.toggleModal}
                >
                  Done
                </button>
              </div>

              <div className="modal__dots-container">
                <div className="modal__dot" />
                <div className="modal__dot" />
                <div className="modal__dot modal__dot--active" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  ({ enterprise: { partnerInfo } }) => ({ partnerInfo }),
  null,
)(GetStarted);
