import React, { useState } from "react";
import classNames from "classnames";

const CustomReviewInput = ({
  type,
  placeholder,
  handleInputChange,
  disabled,
  inputValue,
  name,
}) => {
  if (type === "textarea") {
    return (
      <textarea
        name={name}
        className="h4 review_form_input"
        onChange={handleInputChange}
        value={inputValue}
        disabled={disabled}
      />
    );
  }

  return (
    <input
      name={name}
      placeholder={placeholder}
      type="text"
      data-testid={`input-${name}`}
      disabled={disabled}
      className="h4 review_form_input"
      onChange={handleInputChange}
      value={inputValue}
    />
  );
};

const ReviewInput = ({
  type,
  initialData,
  theme,
  label,
  name,
  disabled,
  onChange,
  placeholder = "",
  className = "",
  mrz,
}) => {
  const [inputValue, setInputValue] = useState(initialData);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange(event);
  };

  return (
    <div className={classNames("review_form_input_group", className)}>
      <label
        htmlFor={name}
        className={`theme-${theme} h3 review_text review_form_label`}
      >
        {label}
      </label>

      <CustomReviewInput
        name={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        handleInputChange={handleInputChange}
        inputValue={inputValue}
      />
      {mrz && <MrzDetails mrz={mrz} />}
    </div>
  );
};

const ReviewGenderInput = ({
  initialData,
  name,
  theme,
  label,
  disabled,
  onChange,
}) => {
  const [gender, setGender] = useState(initialData);
  const options = ["M", "F"];
  const handleGenderClick = (updatedGender) => {
    if (disabled) {
      return;
    }
    setGender((prevGender) =>
      prevGender === updatedGender ? null : updatedGender,
    );
    onChange({
      target: { value: gender === updatedGender ? "" : updatedGender, name },
    });
  };

  return (
    <div className="review_form_input_group">
      <label className={`theme-${theme} h3 review_text review_form_label`}>
        {label}
      </label>
      <div className="review_form_input_gender">
        {options.map((option) => (
          <button
            key={option}
            className={classNames("h3 review_form_input_gender_button", {
              selected: gender === option,
              disabled,
            })}
            onClick={() => handleGenderClick(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

const ReviewSuggestionTab = ({ iconColor, duration, onClick }) => (
  <div className="review_suggestions_tab p2" onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <circle cx="6" cy="6" r="6" fill={iconColor || "#2D2B2A"} />
      <rect
        x="4.49811"
        y="3.99988"
        width="3.74269"
        height="0.935673"
        rx="0.467836"
        transform="rotate(32.1871 4.49811 3.99988)"
        fill="white"
      />
      <rect
        x="4.11725"
        y="8.21863"
        width="3.74269"
        height="0.467836"
        rx="0.233918"
        transform="rotate(-40 4.11725 8.21863)"
        fill="#FF5805"
      />
    </svg>
    {duration}
  </div>
);

const MrzDetails = ({ level, value }) => {
  const levelClassNames = classNames(
    "text-xs font-medium review_form_mrz_details_level",
    {
      review_form_mrz_details_high: level?.toLowerCase() === "high",
      review_form_mrz_details_low: level?.toLowerCase() === "low",
    },
  );
  return (
    <div className="review_form_mrz_details">
      <div className="text-xs font-medium review_form_mrz_details_label">
        MRZ:
      </div>
      <div className={levelClassNames}>{level}</div>
      <div className="text-xs font-medium review_form_mrz_details_value">
        {value}
      </div>
    </div>
  );
};

export { ReviewInput, ReviewGenderInput, ReviewSuggestionTab };
