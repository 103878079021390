import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconKYCRejected from "resources/img/icons/account-rejected.svg";
import IconKYCAccepted from "resources/img/icons/account-verified.svg";
import ClipboardIcon from "resources/img/icons/clipboard.svg";
import DotIcon from "resources/img/icons/dot.svg";
import errorWarning from "resources/img/icons/error-warning.svg";
import IconFile from "resources/img/icons/file.svg";
import IconInfo from "resources/img/icons/info--grey.svg";
import KYCCompleted from "resources/img/icons/kyc-completed.svg";
import KYCConfirmation from "resources/img/icons/kyc-confirmation.svg";
import KYCRejected from "resources/img/icons/kyc-rejected.svg";
import IconKYCSubmitted from "resources/img/icons/kyc_submitted.svg";
import IconTick from "resources/img/icons/tick--filled-green.svg";
import IconWarn from "resources/img/icons/triangle-warning.svg";
import IconKYCReview from "resources/img/icons/under-review.svg";
import RedXIcon from "resources/img/icons/x--red.svg";
import SaveImg from "resources/img/save.png";
import {
  createKYCDetail,
  fetchKYCDetail,
  fetchPartnerInfo,
  updateKYCDetail,
  updateKYCState,
} from "util/api_util";
import { decode } from "util/route_util";
import { getUserType } from "util/selectors";

class KYCForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerID: this.props.partnerID || decode(localStorage.token).partner_id,
      approvingKYC: false,
      rejectingKYC: false,
      rejectionComment: "",
      userType: getUserType(),
      id: undefined,
      country: undefined,
      selectedCountry: undefined,
      legal_name: undefined,
      incorporation_doc: undefined,
      additional_doc: undefined,
      director_name: undefined,
      director_title: undefined,
      director_id_type: undefined,
      director_id: undefined,
      director_country: undefined,
      director_email: undefined,
      contact_name: undefined,
      contact_title: undefined,
      contact_email: undefined,
      contact_phone: undefined,
      comment: undefined,
      partnerInfo: {},
      disabled: false,
      fileSizeErrors: {},
      modalOpen: false,
    };
    this.onInput = this.onInput.bind(this);
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
    this.approveKYC = this.approveKYC.bind(this);
    this.rejectKYC = this.rejectKYC.bind(this);
    this.submitKYCApproval = this.submitKYCApproval.bind(this);
    this.submitKYCRejection = this.submitKYCRejection.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.buildForm = this.buildForm.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.validate = this.validate.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  componentDidMount() {
    fetchKYCDetail({ id: this.state.partnerID })
      .then((kyc_detail) => {
        if (!kyc_detail || kyc_detail.error) {
          return;
        }
        let selectedCountry = "";
        if (kyc_detail.country) {
          selectedCountry = this.getValidationCriteria()[kyc_detail.country]
            ? kyc_detail.country
            : "Other";
        }
        this.setState({
          id: kyc_detail.id || undefined,
          country: kyc_detail.country || undefined,
          selectedCountry,
          legal_name: kyc_detail.legal_name || undefined,
          incorporation_doc: kyc_detail.incorporation_doc || undefined,
          additional_doc: kyc_detail.additional_doc || undefined,
          director_name: kyc_detail.director_name || undefined,
          director_title: kyc_detail.director_title || undefined,
          director_id_type: kyc_detail.director_id_type || undefined,
          director_id: kyc_detail.director_id || undefined,
          director_country: kyc_detail.director_country || undefined,
          director_email: kyc_detail.director_email || undefined,
          contact_name: kyc_detail.contact_name || undefined,
          contact_title: kyc_detail.contact_title || undefined,
          contact_email: kyc_detail.contact_email || undefined,
          contact_phone: kyc_detail.contact_phone || undefined,
          comment: kyc_detail.comment,
        });
      })
      .catch((err) => console.error(err));

    fetchPartnerInfo({ partner: this.state.partnerID }).then((resp) => {
      let kycState;
      if (resp.partner && resp.partner.company) {
        kycState = resp.partner.company.kyc_state;
      }
      this.setState({
        partnerInfo: resp.partner || this.state.partnerInfo,
        kycState: kycState || this.state.kycState,
      });
    });
  }

  getProgress() {
    let counter = 0;
    [
      "country",
      "legal_name",
      "incorporation_doc",
      "additional_doc",
      "director_name",
      "director_title",
      "director_id_type",
      "director_id",
      "director_country",
      "director_email",
      "contact_name",
      "contact_title",
      "contact_email",
      "contact_phone",
    ].forEach((key) => {
      if (this.state[key]) {
        counter += 1;
      }
    });
    return counter;
  }

  buildForm() {
    const formData = new FormData();
    if (this.state.id) {
      formData.set("id", this.state.id);
    }
    if (this.state.country) {
      formData.set("country", this.state.country);
    }
    if (this.state.legal_name) {
      formData.set("legal_name", this.state.legal_name);
    }
    if (this.state.director_name) {
      formData.set("director_name", this.state.director_name);
    }
    if (this.state.director_title) {
      formData.set("director_title", this.state.director_title);
    }
    if (this.state.director_id_type) {
      formData.set("director_id_type", this.state.director_id_type);
    }
    if (this.state.director_country) {
      formData.set("director_country", this.state.director_country);
    }
    if (this.state.director_email) {
      formData.set("director_email", this.state.director_email);
    }
    if (this.state.contact_name) {
      formData.set("contact_name", this.state.contact_name);
    }
    if (this.state.contact_title) {
      formData.set("contact_title", this.state.contact_title);
    }
    if (this.state.contact_email) {
      formData.set("contact_email", this.state.contact_email);
    }
    if (this.state.contact_phone) {
      formData.set("contact_phone", this.state.contact_phone);
    }
    ["incorporation_doc", "additional_doc", "director_id"].forEach((file) => {
      if (!this.state[file]) {
        // do nothing
      } else if (typeof this.state[file].link === "string") {
        formData.set(file, this.state[file].name);
      } else {
        formData.set(file, this.state[file], this.state[file].name);
      }
    });
    return formData;
  }

  async save(e, keepStateDisabled) {
    this.setState({
      disabled: true,
    });

    const form = this.buildForm();

    if (!this.state.id) {
      try {
        const resp = await createKYCDetail(form);

        if (resp.error) throw resp;

        this.setState({
          id: resp.id,
          disabled: keepStateDisabled || false,
          incorporation_doc: resp.incorporation_doc,
          additional_doc: resp.additional_doc,
          director_id: resp.director_id,
          modalOpen: !keepStateDisabled,
          kycState: resp.company.kyc_state,
        });

        return resp;
      } catch (err) {
        let errorMessage = err.error;
        if (errorMessage === "Not Authorized") {
          errorMessage = "Only account owners can submit KYC";
        }
        if (!keepStateDisabled) {
          this.setState({
            error: errorMessage,
            disabled: keepStateDisabled || false,
          });
        } else {
          throw err;
        }
      }
    } else {
      try {
        const resp = await updateKYCDetail(form);

        if (resp.error) throw resp;

        this.setState({
          disabled: keepStateDisabled || false,
          incorporation_doc: resp.incorporation_doc,
          additional_doc: resp.additional_doc,
          director_id: resp.director_id,
          modalOpen: !keepStateDisabled,
          kycState: resp.company.kyc_state,
        });

        return resp;
      } catch (err) {
        // fix the message. the error is re-thrown when re-submitting kyc
        if (err.error === "Not Authorized") {
          err.error = "Only account owners can submit KYC";
        }
        if (!keepStateDisabled) {
          this.setState({
            error: err.error,
            disabled: false,
          });
        } else {
          throw err;
        }
      }
    }
  }

  approveKYC() {
    this.setState({
      modalOpen: true,
      approvingKYC: true,
    });
  }

  rejectKYC() {
    this.setState({
      modalOpen: true,
      rejectingKYC: true,
    });
  }

  getValidationCriteria() {
    // TODO: move this to dynamo and retrieve it dynamically so we can avoid having to update code on the fly
    return {
      Nigeria: {
        incorporation_doc: "CAC Certificate",
        additional_doc: undefined,
      },
      Ghana: {
        incorporation_doc: "Certificate of Incorporation",
        additional_doc: "Form 3",
      },
      Kenya: {
        incorporation_doc:
          "KRA PIN Certificate or Certificate of Incorporation",
        additional_doc: "CR1",
      },
      Tanzania: {
        incorporation_doc: "BRELA",
        additional_doc: "Form 14a",
      },
      Uganda: {
        incorporation_doc: "Certificate of Incorporation",
        additional_doc: "Company Form 20",
      },
      "South Africa": {
        incorporation_doc: "CoR 14.1",
        additional_doc: undefined,
      },
      "United States": {
        incorporation_doc: "Delaware Certificate of Incorporation",
        additional_doc: undefined,
      },
      UK: {
        incorporation_doc: "Certificate of Incorporation",
        additional_doc: undefined,
      },
      Canada: {
        incorporation_doc: "Certificate of Incorporation",
        additional_doc: undefined,
      },
      Other: {
        incorporation_doc:
          "Incorporation document containing your company’s legal name and names of directors",
        additional_doc:
          "Document containing your company’s legal name and names of directors (if Incorporation document does not include Director’s information)",
      },
    };
  }

  validate() {
    const fields = [
      "country",
      "legal_name",
      "incorporation_doc",
      "additional_doc",
      "director_name",
      "director_title",
      "director_id_type",
      "director_id",
      "director_country",
      "director_email",
      "contact_name",
      "contact_title",
      "contact_email",
      "contact_phone",
    ];
    let valid = true;
    const validationCriteria =
      this.getValidationCriteria()[this.state.selectedCountry] || {};
    for (let i = 0; i < fields.length; i += 1) {
      if (
        fields[i] === "additional_doc" &&
        !validationCriteria.additional_doc
      ) {
        continue;
      }
      if (!this.state[fields[i]]) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  async submit(e) {
    try {
      const saved = await this.save(e, true);
      if (saved) {
        const resp = await updateKYCState({ kyc_state: "submitted" });

        if (resp.error) throw resp;

        const state = {
          disbled: false,
        };

        if (resp.success) {
          state.kycState = "submitted";
        }

        this.setState(state);
      }
    } catch (err) {
      this.setState({
        error: err.error,
        disabled: true,
      });
    }
  }

  submitKYCApproval() {
    this.setState({ disabled: true });

    updateKYCState({
      kyc_state: "accepted",
      partner_id: this.state.partnerID,
    }).then((resp) => {
      try {
        if (resp.error) throw resp;
        const state = { disabled: false };
        if (resp.success) {
          state.kycState = "accepted";
        }
        this.setState(state);
      } catch (error) {
        this.setState({
          modalOpen: false,
          error: error.error,
          disabled: false,
        });
      }
    });
  }

  async submitKYCRejection() {
    this.setState({ disabled: true, error: "" });

    try {
      const resp = await updateKYCState({
        kyc_state: "rejected",
        partner_id: this.state.partnerID,
        comment: this.state.rejectionComment,
      });

      if (resp.error) throw resp;

      const state = { disabled: false };
      if (resp.success) {
        state.kycState = "rejected";
      }
      this.setState(state);
    } catch (error) {
      this.setState({
        modalOpen: false,
        error: error.error,
        disabled: false,
      });
    }
  }

  removeFile(property) {
    return () => {
      this.setState({ [property]: undefined });
    };
  }

  handleFile(property) {
    return (e) => {
      const twoMB = 2 * 1024 * 1024;
      if (e.target.files[0].size > twoMB) {
        this.setState({
          fileSizeErrors: {
            ...this.state.fileSizeErrors,
            [e.target.name]: true,
          },
        });
        return;
      }

      this.setState({
        fileSizeErrors: {
          ...this.state.fileSizeErrors,
          [e.target.name]: false,
        },
      });

      this.setState({ [property]: e.target.files[0] });
    };
  }

  onInput(property) {
    return (e) => {
      if (property === "selectedCountry") {
        this.setState({
          [property]: e.target.value,
          country: e.target.value === "Other" ? undefined : e.target.value,
        });
      }
      this.setState({ [property]: e.target.value });
    };
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
      approvingKYC: false,
      rejectingKYC: false,
      rejectionComment: "",
    });
  }

  dismissError() {
    this.setState({
      error: undefined,
    });
  }

  render() {
    const progress = this.getProgress();
    const companyName = this.state.partnerInfo.company
      ? this.state.partnerInfo.company.name
      : "";
    let modal;
    let footer;
    let headerStatusIcon;
    let headerStatusText;
    let banner;
    const countryOptions = [<option value="" key="blank" />];
    Object.keys(this.getValidationCriteria()).forEach((key) => {
      countryOptions.push(
        <option value={key} key={key}>
          {key}
        </option>,
      );
    });
    const hasFileSizeErrors = Object.values(this.state.fileSizeErrors).some(
      (error) => error,
    );
    if (
      !["submitted", "under_review", "accepted"].includes(
        this.state.kycState,
      ) &&
      this.state.userType !== "admin"
    ) {
      const validForm = this.validate();
      footer = (
        <div className="kyc-form__footer">
          <button
            className="btn btn-white"
            onClick={this.save}
            disabled={this.state.disabled || hasFileSizeErrors}
          >
            Save
          </button>
          <button
            className="btn btn-primary"
            onClick={this.submit}
            disabled={this.state.disabled || !validForm}
          >
            Submit
          </button>
        </div>
      );
    }

    if (
      this.state.userType === "admin" &&
      this.state.kycState === "submitted"
    ) {
      footer = (
        <div className="kyc-form__footer">
          <button
            className="btn btn--red"
            onClick={this.rejectKYC}
            disabled={this.state.disabled || this.state.kycState === "rejected"}
          >
            Reject
          </button>
          <button
            className="btn btn-primary"
            onClick={this.approveKYC}
            disabled={this.state.disabled || this.state.kycState === "accepted"}
          >
            Approve
          </button>
        </div>
      );
    }

    if (
      ["submitted", "under_review", "accepted", "rejected"].includes(
        this.state.kycState,
      )
    ) {
      const stateText = {
        submitted: "Submitted",
        under_review: "Under Review",
        accepted: "Account Verified",
        rejected: "Account Not Verified",
      }[this.state.kycState];
      headerStatusText = <span>{stateText}</span>;
      const stateIcon = {
        submitted: IconKYCSubmitted,
        under_review: IconKYCReview,
        accepted: IconKYCAccepted,
        rejected: IconKYCRejected,
      }[this.state.kycState];
      headerStatusIcon = <img src={stateIcon} alt={this.state.kycState} />;
    }

    if (this.state.modalOpen) {
      if (this.state.userType === "admin") {
        if (this.state.approvingKYC) {
          modal = (
            <div className="kyc-form__modal">
              <div className="kyc-form__modal--body">
                <img src={KYCConfirmation} alt="confirm kyc approval" />
                <h2>Are you sure?</h2>
                <p>
                  You are passing a final approval on partner{" "}
                  {this.state.partnerID} after a thorough review of all
                  submitted company documents and ID details.
                </p>
                <button
                  className="btn btn-primary"
                  onClick={this.submitKYCApproval}
                >
                  Yes, I approve partner
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={this.toggleModal}
                >
                  View Review Page
                </button>
              </div>
            </div>
          );

          if (this.state.kycState === "accepted") {
            modal = (
              <div className="kyc-form__modal">
                <div className="kyc-form__modal--body">
                  <img src={KYCCompleted} alt="partner kyc completed" />
                  <h2>Partner KYC Completed</h2>
                  <p>
                    Partner {this.state.partnerID} will receive an email
                    notification about the successful activation of their
                    account.
                  </p>
                  <Link
                    to="/admin/kyc_reviews"
                    className="btn btn-primary link-btn"
                  >
                    Back to Review Board
                  </Link>
                </div>
              </div>
            );
          }
        }

        if (this.state.rejectingKYC) {
          modal = (
            <div className="kyc-form__modal">
              <div className="kyc-form__modal--body">
                <h2>
                  Partner {this.state.partnerID} <br /> Account Rejected
                </h2>
                <label>
                  <p>Reason for the rejection</p>
                  <textarea
                    value={this.state.rejectionComment}
                    onChange={this.onInput("rejectionComment")}
                    name="rejectionComment"
                    rows="5"
                    placeholder="e.g. invalid incorporation document, blurry passport id"
                  />
                </label>
                <p className="failure">{this.state.error}</p>
                <button
                  className="btn btn--red"
                  onClick={this.submitKYCRejection}
                >
                  Confirm Rejection
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={this.toggleModal}
                >
                  View Review Page
                </button>
              </div>
            </div>
          );

          if (this.state.kycState === "rejected") {
            modal = (
              <div className="kyc-form__modal">
                <div className="kyc-form__modal--body">
                  <img src={KYCRejected} alt="partner kyc rejected" />
                  <h2>Partner KYC Rejected</h2>
                  <p>
                    Partner {this.state.partnerID} will receive an email
                    notification about the failed attempt to verify their
                    account.
                  </p>
                  <Link
                    to="/admin/kyc_reviews"
                    className="btn btn-primary link-btn"
                  >
                    Back to Review Board
                  </Link>
                </div>
              </div>
            );
          }
        }
      } else {
        modal = (
          <div className="kyc-form__modal">
            <div className="kyc-form__modal--body">
              <img src={SaveImg} alt="save and continue" />
              <h2>KYC Details Saved!</h2>
              <p>
                All details provided so far have been saved. You can continue
                from the same stage at any time.
              </p>
              <button className="btn btn-primary" onClick={this.toggleModal}>
                Back to KYC
              </button>
            </div>
          </div>
        );
      }
    }

    if (this.state.kycState === "accepted") {
      banner = (
        <div className="kyc-form__banner kyc-form__banner--accepted">
          <img src={IconTick} alt="account verified" />
          <p>
            Your account has been verified. You may now run jobs in production.
          </p>
        </div>
      );
    }
    if (this.state.kycState === "rejected") {
      banner = (
        <div className="kyc-form__banner kyc-form__banner--rejected">
          <img src={IconWarn} alt="account not verified" />
          <p>{this.state.comment} Please take a moment to make changes.</p>
        </div>
      );
    }

    let validationCriteria = this.getValidationCriteria()[this.state.country];
    if (!validationCriteria) {
      validationCriteria = this.getValidationCriteria().Other;
    }
    const fileSizeLimitMessage = "size should be less 2 MB";

    return (
      <>
        {modal}
        {this.state.error && (
          <div className="no-info__container">
            <img
              className="icon--large pointer"
              src={errorWarning}
              alt="Error"
              onClick={this.dismissError}
            />
            <div className="no-info">{this.state.error}</div>
          </div>
        )}

        {!this.state.error &&
          (this.state.partnerInfo ? (
            <div className="kyc-form">
              <div className="kyc-form__header">
                <img
                  className="kyc-form__header--icon"
                  src={ClipboardIcon}
                  alt="header-icon"
                />
                <div className="kyc-form__header--content">
                  <div className="kyc-form__header--id">
                    <img
                      className="kyc-form__header--icon"
                      src={DotIcon}
                      alt="header-icon"
                    />
                    &nbsp;
                    <span className="kyc-form__header--label">Partner ID:</span>
                    &nbsp;
                    {this.props.partnerID}
                  </div>
                  <div className="kyc-form__header--name">{companyName}</div>
                  <div className="kyc-form__header--progress">
                    <div className="kyc-form__header--progress-text">
                      <p>ID Verification Progress</p>
                      <p>{progress}/14</p>
                    </div>
                    <div className="kyc-form__header--progress-bar">
                      <div
                        style={{
                          height: ".25em",
                          width: `${(progress / 14) * 100}%`,
                          backgroundColor: "var(--products-lightblue)",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="kyc-form__header--state-info">
                  <p>
                    {headerStatusText}
                    {headerStatusIcon}
                  </p>
                  {this.state.userType === "admin" &&
                    this.state.kycState === "accepted" && (
                      <p>{this.state.comment}</p>
                    )}
                </div>
              </div>
              <div className="kyc-form__body">
                {banner}
                <div className="kyc-form__body-section">
                  <div className="kyc-form__body-section-header">
                    <p>Company Details</p>
                    <hr />
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Country of Incorporation</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.country}</p>
                    ) : (
                      <select
                        className="input"
                        value={this.state.selectedCountry || ""}
                        name="selectedCountry"
                        onChange={this.onInput("selectedCountry")}
                      >
                        {countryOptions}
                      </select>
                    )}
                    {this.state.userType !== "admin" &&
                      this.state.selectedCountry === "Other" && (
                        <input
                          className="input"
                          type="text"
                          value={this.state.country}
                          onChange={this.onInput("country")}
                          name="country"
                        />
                      )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Company Legal Name</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.legal_name}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.legal_name}
                        onChange={this.onInput("legal_name")}
                        name="legal_name"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>
                      <div className="kyc-form__info-text">
                        Incorporation Document
                        <div className="kyc-tooltip-container">
                          <button
                            type="button"
                            className="kyc-tooltip icon-btn"
                          >
                            <img
                              src={IconInfo}
                              alt="more information"
                              className="kyc-form__info-icon"
                            />
                          </button>
                          <span role="tooltip">
                            {validationCriteria.incorporation_doc}
                          </span>
                        </div>
                      </div>
                      <div className="pdfLabel">
                        (.pdf, {fileSizeLimitMessage})
                      </div>
                    </label>

                    {this.state.incorporation_doc &&
                    typeof this.state.incorporation_doc.name === "string" ? (
                      <div className="kyc_form__uploaded-file">
                        <a href={this.state.incorporation_doc.link}>
                          <img src={IconFile} alt="file icon" />
                          <span>{this.state.incorporation_doc.name}</span>
                        </a>
                        {this.state.userType !== "admin" && (
                          <button
                            className="icon-btn"
                            aria-label="remove incorporation doc"
                            onClick={this.removeFile("incorporation_doc")}
                          >
                            <img alt="remove" src={RedXIcon} />
                          </button>
                        )}
                      </div>
                    ) : (
                      <div>
                        <input
                          accept=".pdf"
                          className="input"
                          type="file"
                          onChange={this.handleFile("incorporation_doc")}
                          name="incorporation_doc"
                        />
                        {this.state.fileSizeErrors.incorporation_doc && (
                          <div className="kyc_form__uploaded-error">
                            The file needs to be less than 2 MB.
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>
                      <div className="kyc-form__info-text">
                        Additional Document
                        <div className="kyc-tooltip-container">
                          <button
                            type="button"
                            className="kyc-tooltip icon-btn"
                          >
                            <img
                              src={IconInfo}
                              alt="more information"
                              className="kyc-form__info-icon"
                            />
                          </button>

                          <span role="tooltip">
                            {validationCriteria.additional_doc ||
                              `Not required in ${this.state.country}`}
                          </span>
                        </div>
                      </div>
                      <div className="pdfLabel">
                        (.pdf, {fileSizeLimitMessage})
                      </div>
                    </label>

                    {this.state.additional_doc &&
                    typeof this.state.additional_doc.name === "string" ? (
                      <div className="kyc_form__uploaded-file">
                        <a href={this.state.additional_doc.link}>
                          <img src={IconFile} alt="file icon" />
                          <span>{this.state.additional_doc.name}</span>
                        </a>
                        {this.state.userType !== "admin" && (
                          <button
                            className="icon-btn"
                            aria-label="remove additional doc"
                            onClick={this.removeFile("additional_doc")}
                          >
                            <img alt="remove" src={RedXIcon} />
                          </button>
                        )}
                      </div>
                    ) : this.state.userType !== "admin" ? (
                      <div>
                        <input
                          accept=".pdf"
                          className="input"
                          type="file"
                          onChange={this.handleFile("additional_doc")}
                          name="additional_doc"
                        />
                        {this.state.fileSizeErrors.additional_doc && (
                          <div className="kyc_form__uploaded-error">
                            The file needs to be less than 2 MB.
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="kyc-form__body-section">
                  <div className="kyc-form__body-section-header">
                    <p>Director's Details</p>
                    <hr />
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Full Name</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.director_name}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.director_name}
                        onChange={this.onInput("director_name")}
                        name="director_name"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Designation</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.director_title}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.director_title}
                        onChange={this.onInput("director_title")}
                        name="director_title"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>
                      <div>
                        Director's ID Card
                        <div className="pdfLabel">
                          (.png .jpg, {fileSizeLimitMessage})
                        </div>
                      </div>
                    </label>
                    {this.state.director_id &&
                    typeof this.state.director_id.name === "string" ? (
                      <div className="kyc_form__uploaded-file">
                        <a href={this.state.director_id.link}>
                          <img src={IconFile} alt="file icon" />
                          <span>{this.state.director_id.name}</span>
                        </a>
                        {this.state.userType !== "admin" && (
                          <button
                            className="icon-btn"
                            aria-label="remove director id"
                            onClick={this.removeFile("director_id")}
                          >
                            <img alt="remove" src={RedXIcon} />
                          </button>
                        )}
                      </div>
                    ) : (
                      <div>
                        <input
                          accept="image/png, image/jpeg"
                          className="input"
                          type="file"
                          onChange={this.handleFile("director_id")}
                          name="director_id"
                        />
                        {this.state.fileSizeErrors.director_id && (
                          <div className="kyc_form__uploaded-error">
                            The file needs to be less than 2 MB.
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>ID Type</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.director_id_type}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.director_id_type}
                        onChange={this.onInput("director_id_type")}
                        name="director_id_type"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Country of Issuance</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.director_country}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.director_country}
                        onChange={this.onInput("director_country")}
                        name="director_country"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Email</label>
                    {this.state.userType === "admin" ? (
                      <p>
                        <a
                          href={`mailto:${this.state.director_email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {this.state.director_email}
                        </a>
                      </p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.director_email}
                        onChange={this.onInput("director_email")}
                        name="director_email"
                      />
                    )}
                  </div>
                </div>
                <div className="kyc-form__body-section">
                  <div className="kyc-form__body-section-header">
                    <p>Contact Details</p>
                    <hr />
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Full Name</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.contact_name}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.contact_name}
                        onChange={this.onInput("contact_name")}
                        name="contact_name"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Designation</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.contact_title}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.contact_title}
                        onChange={this.onInput("contact_title")}
                        name="contact_title"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Phone Number</label>
                    {this.state.userType === "admin" ? (
                      <p>{this.state.contact_phone}</p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.contact_phone}
                        onChange={this.onInput("contact_phone")}
                        name="contact_phone"
                      />
                    )}
                  </div>
                  <div className="kyc-form__body-input">
                    <label>Email</label>
                    {this.state.userType === "admin" ? (
                      <p>
                        <a
                          href={`mailto:${this.state.contact_email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {this.state.contact_email}
                        </a>
                      </p>
                    ) : (
                      <input
                        className="input"
                        type="text"
                        value={this.state.contact_email}
                        onChange={this.onInput("contact_email")}
                        name="contact_email"
                      />
                    )}
                  </div>
                </div>
              </div>
              {footer}
            </div>
          ) : (
            <div className="loader__container">
              <div className="loader loader--centered" />
            </div>
          ))}
      </>
    );
  }
}

export default KYCForm;
