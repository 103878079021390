import React from "react";

import Accordion from "components/ui/Accordion";
import UserInfoIcon from "resources/img/icons/UserInfo.svg";
import { getCountry, toTitleCase } from "util/format_helpers";
import {
  Fields,
  ImgIcon,
  InfoICon,
  JobField,
  NoResultDiv,
  NOT_AVAILABLE,
  TriggerDiv,
  TextSpan,
} from "./IdInfoAccordion";

type Props = {
  userInformation: {
    pii_data_expired: boolean;
    user_detail_first_name?: string;
    user_detail_middle_name?: string;
    user_detail_last_name?: string;
    user_detail_country?: string;
    user_detail_id_type?: string;
    user_detail_id_number?: string;
    user_detail_dob?: string;
    original_id_photo?: string;
    phone_number?: string;
  };
  jobType: number;
};

const UserProvidedInfoAccordion: React.FC<Props> = ({
  userInformation,
  jobType,
}) => {
  const piiDataExpired = userInformation.pii_data_expired;
  const notApplicable = ![1, 5, 6, 11, 13].includes(jobType);

  const trigger = (
    <TriggerDiv>
      <ImgIcon src={UserInfoIcon} alt="user provided data" />
      <span>User Provided Information</span>
    </TriggerDiv>
  );

  let fields = [
    {
      key: "user_detail_first_name",
      label: "First Name",
      value: toTitleCase(
        userInformation.user_detail_first_name || NOT_AVAILABLE,
      ),
    },
    {
      key: "user_detail_middle_name",
      label: "Middle Name",
      value: toTitleCase(
        userInformation.user_detail_middle_name || NOT_AVAILABLE,
      ),
    },
    {
      key: "user_detail_last_name",
      label: "Last Name",
      value: toTitleCase(
        userInformation.user_detail_last_name || NOT_AVAILABLE,
      ),
    },
    {
      key: "user_detail_country",
      label: "Country",
      value:
        getCountry(userInformation.user_detail_country) ||
        userInformation.user_detail_country ||
        NOT_AVAILABLE,
    },
    {
      key: "user_detail_id_type",
      label: "ID Type",
      value: userInformation.user_detail_id_type || NOT_AVAILABLE,
    },
    {
      key: "user_detail_id_number",
      label: "ID Number",
      value: userInformation.user_detail_id_number || NOT_AVAILABLE,
    },
    {
      key: "user_detail_dob",
      label: "Date of Birth",
      value: userInformation.user_detail_dob || NOT_AVAILABLE,
    },
    {
      key: "phone_number",
      label: "Phone Number",
      value: userInformation.phone_number || NOT_AVAILABLE,
    },
  ];

  // Note: For JT13 only show these fields
  if (jobType === 13) {
    fields = fields.filter((field) =>
      [
        "phone_number",
        "user_detail_country",
        "user_detail_first_name",
        "user_detail_last_name",
        "user_detail_id_type",
        "user_detail_middle_name",
        "user_detail_id_number",
      ].includes(field.key),
    );
  }

  const hasNoInfo = fields.every((field) => field.value === NOT_AVAILABLE);

  const notApplicableIcon = notApplicable ? (
    <TextSpan>Not Applicable</TextSpan>
  ) : null;

  return (
    <Accordion
      disabled={piiDataExpired || notApplicable}
      defaultValue={piiDataExpired ? "user_information" : undefined}
      collapsible={!piiDataExpired}
    >
      <Accordion.Item
        icon={notApplicableIcon}
        showIcon={!piiDataExpired}
        trigger={trigger}
        value="user_information"
      >
        {hasNoInfo ? (
          <div className="no-info">No Information to show</div>
        ) : piiDataExpired ? (
          <>
            <NoResultDiv>
              <InfoICon />
              <span>
                Information no longer available as job is past its data
                retention period.
              </span>
            </NoResultDiv>
            <JobField
              label="ID Type"
              value={userInformation.user_detail_id_type || NOT_AVAILABLE}
            />
          </>
        ) : (
          <Fields>
            {...fields.map((field) => (
              <JobField
                key={field.key}
                label={field.label}
                value={field.value}
              />
            ))}
          </Fields>
        )}
      </Accordion.Item>
    </Accordion>
  );
};

export default UserProvidedInfoAccordion;
