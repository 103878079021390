import React, { Component } from "react";

import KE_ALIEN_CARD_Back from "resources/img/id_provider_cards/KE/ALIEN_CARD_Back_Masked.png";
import KE_ALIEN_CARD_Front from "resources/img/id_provider_cards/KE/ALIEN_CARD_Front_Masked.png";
import KE_NATIONAL_ID_Back from "resources/img/id_provider_cards/KE/NATIONAL_ID_Back_Masked.png";
import KE_NATIONAL_ID_Front from "resources/img/id_provider_cards/KE/NATIONAL_ID_Front_Masked.png";
import KE_PASSPORT from "resources/img/id_provider_cards/KE/PASSPORT_Masked.png";
import { decode } from "util/route_util";

import Carousel from "./carousel";
import ReviewImage from "./review_image";

class IDCardReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: false,
      rejection_reason: undefined,
      disabled: false,
      submitted: false,
      id_type: "",
    };
    this.toggleFooter = this.toggleFooter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.review.complete) {
      this.setState({
        rejection_reason:
          this.props.review.smile_reference &&
          this.props.review.smile_reference.internal.Payload.RejectionReason,
      });
    }
  }

  toggleFooter() {
    const { footer } = this.state;
    this.setState({
      footer: !footer,
    });
  }

  validateForm(review) {
    let valid = true;
    let error;
    review.textual_comparisons.forEach((comparison) => {
      if (
        comparison.key === "id_type" &&
        comparison.result !== "correct" &&
        !this.state.rejection_reason
      ) {
        valid = false;
        error = "An ID rejection reason must be selected if the ID is invalid.";
      } else if (!comparison.result) {
        valid = false;
        error = `A decision on ${comparison.key
          .replace("_", " ")
          .toUpperCase()} is required`;
      }
    });
    return [valid, error];
  }

  handleSubmit(e) {
    this.setState({
      disabled: true,
    });
    e.preventDefault();
    const APIform = {
      review: {
        textual_comparisons: this.props.review.links.textual_comparisons,
        rejection_reason: this.state.rejection_reason
          ? this.state.rejection_reason
          : null,
      },
      id: this.props.review.id,
    };
    const [valid, error] = this.validateForm(APIform.review);
    if (!valid) {
      return this.setState({
        error,
        disabled: false,
      });
    }
    if (this.props.endpoint) {
      this.props.endpoint(APIform).then((resp) => {
        this.props.callback(resp, APIform.id);
        if (this.props.countdown) {
          this.props.countdown();
        }
      });
    } else {
      this.props.submitReview(APIform).then((resp) => {
        if (this.props.callback) {
          this.props.callback(resp, APIform.id);
        }
        if (this.props.countdown) {
          this.props.countdown();
        }
      });
    }
    this.setState({
      submitted: true,
    });
  }

  handleChange(e) {
    if (e.target.name === "rejection_reason") {
      return this.setState({
        rejection_reason: e.target.value,
      });
    }
    this.props.review.links.textual_comparisons.forEach((comparison) => {
      if (comparison.key === e.target.name) {
        comparison.result = e.target.value;
      }
    });
  }

  render() {
    let author;
    let error;
    let result;
    if (this.state.error) {
      error = <p className="failure">{this.state.error}</p>;
    }
    const smileReference = this.props.review.smile_reference;

    if (this.props.completed) {
      result = (
        <div className="block--decision">
          {smileReference && smileReference.result.ConfidenceValue && (
            <p
              className={
                smileReference.result.ConfidenceValue === "100"
                  ? "success"
                  : "failure"
              }
            >
              {smileReference.result.ResultText}
            </p>
          )}
        </div>
      );
    }

    if (this.props.completed) {
      author = this.props.review.author;
    } else {
      author = decode(localStorage.token).email;
    }
    const textualComparisons = [];
    let idType = "";
    if (
      this.props.review.links &&
      this.props.review.links.textual_comparisons
    ) {
      let comparisons;
      if (this.props.completed) {
        comparisons = smileReference
          ? smileReference.internal.Payload.Comparisons
          : [];
      } else {
        comparisons = this.props.review.links.textual_comparisons;
      }
      comparisons.forEach((comparison) => {
        let options;
        if (comparison.key.includes("name")) {
          options = [
            <option key="blank" value={undefined} />,
            <option key="correct" name="correct" value="correct">
              CORRECT NAME
            </option>,
            <option key="misspelt" name="misspelt" value="misspelt">
              MISSPELT NAME
            </option>,
            <option key="mismatch" name="mismatch" value="mismatch">
              NAME MISMATCH
            </option>,
            <option key="invalid" name="invalid" value="invalid">
              INVALID NAME
            </option>,
          ];
        } else if (comparison.key === "id_type") {
          const field = comparison.key.replace("_", " ").toUpperCase();
          idType = comparison.value.toUpperCase();
          options = [
            <option key="blank" value={undefined} />,
            <option key="correct" name="correct" value="correct">
              CORRECT {field}
            </option>,
            <option key="incorrect" name="incorrect" value="incorrect">
              INCORRECT {field}
            </option>,
          ];
        } else {
          const field = comparison.key.replace("_", " ").toUpperCase();
          options = [
            <option key="blank" value={undefined} />,
            <option key="correct" name="correct" value="correct">
              CORRECT {field}
            </option>,
            <option key="incorrect" name="incorrect" value="incorrect">
              INCORRECT {field}
            </option>,
            <option key="invalid" name="invalid" value="invalid">
              INVALID {field}
            </option>,
          ];
        }
        let selectOrResult;
        const field = comparison.key.includes("name")
          ? "NAME"
          : comparison.key.replace("_", " ").toUpperCase();
        if (this.props.completed) {
          selectOrResult = (
            <label>
              {comparison.result} {field}
            </label>
          );
        } else {
          selectOrResult = (
            <select
              name={comparison.key}
              value={comparison.result}
              disabled={this.state.disabled}
              onChange={this.handleChange}
            >
              {options}
            </select>
          );
        }

        textualComparisons.push(
          <tr key={comparison.key}>
            <td>
              <label>{comparison.key.replace("_", " ").toUpperCase()}</label>
            </td>
            <td>
              <label>{comparison.value}</label>
            </td>
            <td>{selectOrResult}</td>
          </tr>,
        );
      });
      const rejectionOptions = [<option key="blank" value={undefined} />];
      [
        "INVALID",
        "BLURRY",
        "UNREADABLE",
        "DAMAGED",
        "EXPIRED",
        "FRAUDULENT",
      ].forEach((reason) => {
        rejectionOptions.push(
          <option key={reason} value={reason}>
            {reason === "INVALID"
              ? "INVALID / INCORRECT / UNAUTHORIZED"
              : reason.toUpperCase()}{" "}
            ID
          </option>,
        );
      });
      let selectOrReason;
      if (this.props.completed) {
        selectOrReason = (
          <label>
            {smileReference
              ? smileReference.internal.Payload.RejectionReason
              : "Unknown"}
          </label>
        );
      } else {
        selectOrReason = (
          <select
            name="rejection_reason"
            value={this.state.rejection_reason}
            disabled={this.state.disabled}
            onChange={this.handleChange}
          >
            {rejectionOptions}
          </select>
        );
      }

      textualComparisons.push(
        <tr key="rejection_reason">
          <td>
            <label>ID Rejection Reason</label>
          </td>
          <td>
            <label />
          </td>
          <td>{selectOrReason}</td>
        </tr>,
      );
    }

    const frontTemplate = {
      KE_ALIEN_CARD: KE_ALIEN_CARD_Front,
      KE_NATIONAL_ID: KE_NATIONAL_ID_Front,
      KE_PASSPORT,
    }[`${this.props.review.country}_${idType}`];

    const backTemplate = {
      KE_ALIEN_CARD: KE_ALIEN_CARD_Back,
      KE_NATIONAL_ID: KE_NATIONAL_ID_Back,
    }[`${this.props.review.country}_${idType}`];

    const hasTemplates = !!frontTemplate;

    return (
      <div className="container__content update-image__container">
        {!this.props.completed && (
          <>
            <div className="newsmile-heading-h1 update-image__header">
              Verify ID Card
            </div>

            <div className="update-image__details">
              <div className="update-image__detail">
                <div className="update-image__label">User ID</div>
                <div>
                  {this.props.user_id ||
                    this.props.review.job.partner_params.user_id}
                </div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Partner</div>
                <div>{this.props.review.job.partner_id}</div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Job ID</div>
                <div>{this.props.review.job.job_id}</div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Created at</div>
                <div>
                  {new Date(this.props.review.created_at).toLocaleString()}
                </div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Author</div>
                <div>{author}</div>
              </div>
            </div>
          </>
        )}

        {this.props.completed && (
          <ul className="sidebar_jobid">
            <li>
              <span>ID Card Review</span>
            </li>
            <li>
              <label>Job id</label>
              <span id="jobid_number">{`${this.props.review.job.partner_id}-${this.props.review.job.job_id}`}</span>
            </li>
            <li>
              <label>First sent at</label>
              <span id="jobid_firstsentat">
                {new Date(this.props.review.first_sent_at).toLocaleString()}
              </span>
            </li>
            <li>
              <label>Author</label>
              <span id="jobid_autor">{this.props.review.author}</span>
            </li>
          </ul>
        )}

        <div className="review--id-card">
          <div className="review-table-container">
            <h3 className="review-templates--title">User Input Data Check</h3>
            <table>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>{hasTemplates ? "ID Authority" : "User Input"}</th>
                  <th>Decision</th>
                </tr>
              </thead>

              <tbody>{textualComparisons.map((row) => row)}</tbody>
            </table>
            <div className="review__actions">
              <div>{error}</div>
              {this.props.completed ? (
                <button
                  className="btn review-button btn-secondary-inline"
                  onClick={this.props.undo}
                >
                  edit
                </button>
              ) : (
                <button
                  className="btn review-button btn-secondary"
                  onClick={this.handleSubmit}
                  disabled={this.state.disabled}
                >
                  Submit Review
                </button>
              )}
            </div>
          </div>

          <div id="submitted_id_card_review">
            <h3 className="review-templates--title">Submitted ID card</h3>
            <ReviewImage
              showResetZoom
              zoom
              pan
              left={false}
              variant="id_card_review"
              source={this.props.review.source}
            />
          </div>
        </div>
        {result}
        {hasTemplates && (
          <>
            <h3 className="review-templates--title">ID Card Templates</h3>
            <div className="review-templates review-card">
              <Carousel infinite={false} centerMode={false}>
                {frontTemplate && (
                  <ReviewImage zoom left={false} source={frontTemplate} />
                )}
                {backTemplate && (
                  <ReviewImage zoom left={false} source={backTemplate} />
                )}
              </Carousel>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default IDCardReview;
