import React from "react";
import amlstyles from "./aml_results.module.css";
import styles from "./aml_summary_cards.module.css";

function AmlSummaryCards({ summary }) {
  const {
    adverseMedia,
    associations,
    enforcementActions,
    peps: politicallyExposedPerson,
    sanctions,
  } = summary;
  const getClass = (value) => (value > 0 ? styles.error : styles.success);
  const getStatus = (value) => (value > 0 ? "Listed" : "Not publicly reported");

  return (
    <section className={styles.amlCardSummary}>
      <h4 className={amlstyles.sectionTitle}>Results Summary:</h4>
      <table>
        <thead>
          <tr>
            <th className={styles.checksHeader}>Checks</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sanctions List</td>
            <td>
              <span className={getClass(sanctions)}>
                {getStatus(sanctions)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Enforcement Action</td>
            <td>
              <span className={getClass(enforcementActions)}>
                {getStatus(enforcementActions)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Politically Exposed Persons</td>
            <td>
              <span className={getClass(politicallyExposedPerson)}>
                {getStatus(politicallyExposedPerson)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Adverse Media Reports</td>
            <td>
              <span className={getClass(adverseMedia)}>
                {getStatus(adverseMedia)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Known Associations</td>
            <td>
              <span className={getClass(associations)}>
                {getStatus(associations)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default AmlSummaryCards;
