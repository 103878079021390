import React from "react";

import AddCircleIcon from "resources/img/icons/addcircle.svg";
import { css } from "util/format_helpers";
import styles from "./add_button.module.css";

export default function AddButton({
  label,
  className,
  wrapperClassName,
  onClick,
  icon,
  style,
}) {
  return (
    <div
      className={css(wrapperClassName, styles.iconButtonWrapper)}
      style={style}
    >
      <button
        className={css("h4", styles.iconButton, className)}
        onClick={onClick}
      >
        {icon && <img src={AddCircleIcon} className={styles.icon} />} {label}
      </button>
    </div>
  );
}
