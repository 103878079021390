import React from "react";
import Select from "react-select";

import { getJobTypes } from "util/selectors";

function FilterInput(props) {
  const jobTypeOptions = getJobTypes();
  jobTypeOptions.unshift({
    label: "All Job Types",
    value: "All Job Types",
  });

  const resultOptions = [
    { label: "All Results", value: "All Results" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Provisional Approval", value: "Provisional Approval" },
  ];

  const userListOptions = [
    { label: "All Users", value: "All Users" },
    { label: "Watchlisted", value: "watchlisted" },
    {
      label: "Suspected for Fraud",
      value: "suspected_for_fraud",
      isDisabled: !props.smileSecureEnabled,
    },
  ];

  const createDropdown = (options, filterType) => (
    <Select
      key={filterType}
      options={options}
      value={options.find(({ value }) => value === props.filters?.[filterType])}
      onChange={({ value }) => props.changeFilter(filterType, value)}
      defaultValue={options[0]}
    />
  );

  const pageFilters = {
    job_list: [
      { options: jobTypeOptions, filterType: "job_type" },
      { options: resultOptions, filterType: "result" },
    ],
    user_list: [{ options: userListOptions, filterType: "user_type" }],
  };

  const dropdowns = (pageFilters[props.pageName] || []).map(
    ({ options, filterType }) => createDropdown(options, filterType),
  );

  return <div className="filters__select-container">{dropdowns}</div>;
}

export default FilterInput;
