import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import ErrorAndMessageBar from "components/reusable/error_and_message_bar";
import JsonModal from "components/reusable/JsonModal";
import ModalWrapper from "components/reusable/web_links/ModalWrapper";
import {
  createInitialEnvironment,
  DisplayEnvironment,
  getEnvironment,
} from "contexts/displayEnvironment";
import { fetchJob, fetchJobFileLinks } from "util/api_util";
import { biometricJobTypes, jobTypeToProductNameMap } from "util/selectors";
import ReRun from "./re_run";

function DisplayTitle({ environment, jobId, jobType, partnerId, productType }) {
  return (
    <h3 className="job-show-title">
      Partner {partnerId} Job{" "}
      <Link
        className="title-link"
        to={`/admin/${partnerId}/job_results/${environment}/${jobId}`}
      >
        {jobId}
      </Link>
      {jobType ? ` - ${jobTypeToProductNameMap[productType]} JT${jobType}` : ""}
    </h3>
  );
}

function DisplayLogs({ logs = [] }) {
  if (!logs || !Array.isArray(logs)) return <p>No logs available.</p>;
  if (logs.length === 0) return <p>No logs to display.</p>;

  return logs.map((log) => {
    const {
      id,
      ref_id,
      created_at: createdAt,
      partner_params: partnerParams,
    } = log;

    return (
      <React.Fragment key={`log-${id}`}>
        <hr />
        <p>
          <strong>Ref:</strong> {ref_id}
        </p>
        <p>
          <strong>Created at:</strong> {new Date(createdAt).toLocaleString()}
        </p>
        <p>
          <strong>Job Type:</strong> {partnerParams.job_type}
        </p>
        <p>
          <strong>User ID:</strong> {partnerParams.user_id}
        </p>
        <p>
          <strong>Job ID:</strong> {partnerParams.job_id}
        </p>
        <p className="contain-rawjson">
          <strong>Partner params:</strong> {JSON.stringify(partnerParams)}
        </p>
        <br />
        <JsonModal rawJson={log} />
        <br />
      </React.Fragment>
    );
  });
}

function DisplayReferences({ references = [] }) {
  return references.map((reference) => (
    <React.Fragment key={`reference-${reference.id}`}>
      <div>
        <hr />
        <p>
          <strong>Status:</strong> {reference.status}
        </p>
        <p className="contain-rawjson">
          <strong>Result Text:</strong>{" "}
          {JSON.stringify(reference.result.ResultText)}
        </p>
        <p>
          <strong>Result Code:</strong> {reference.result.ResultCode}
        </p>
        <p>
          <strong>Created at:</strong>{" "}
          {new Date(reference.created_at).toLocaleString()}
        </p>
        <br />
        <JsonModal rawJson={reference} />
        <br />
      </div>
    </React.Fragment>
  ));
}

function DisplayMLResults({ mlResults }) {
  if (!mlResults) return <p>No ML results available.</p>;
  return Object.keys(mlResults).map((key) =>
    key !== "ref_id" ? (
      <div key={`ml-result-${key}`}>
        <p>
          <strong>{key.replace("_", " ")} result code:</strong>{" "}
          {Math.round(mlResults[key].result_code)}
        </p>
        <br />
        <JsonModal rawJson={mlResults[key]} />
        <br />
      </div>
    ) : null,
  );
}

function DisplayRelatedJobs({ relatedJobs = [], eventHandler }) {
  return relatedJobs.map((relatedJob) => (
    <div className="related-jobs" key={`related-job-${relatedJob.id}`}>
      <p className="inline">
        {`Partner Job ID: ${relatedJob.job_id} - `}
        {`${jobTypeToProductNameMap[relatedJob.product_type]} JT${
          relatedJob.partner_params.job_type
        }`}
        {` - ${new Date(relatedJob.created_at).toLocaleString()}`}
      </p>
      <button
        onClick={() => eventHandler(relatedJob.id)}
        className="btn btn-primary inline"
        data-id={relatedJob.id}
      >
        Show
      </button>
    </div>
  ));
}

function DisplayFiles({ fileLinks = [] }) {
  return (
    <div className="file-links-container">
      <ul className="file-links-list">
        {fileLinks
          .sort((a, b) => a.name.localeCompare(b.name))
          ?.map((file, index) => (
            <li key={index} className="file-link-item">
              {file.url ? (
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  {file.name}
                </a>
              ) : (
                <span className="file-link-not-found">
                  {`${file.name} - not found`}
                </span>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}

const humanReadableImageTypes = Object.freeze({
  0: "Selfie",
  1: "Document",
  2: "Selfie",
  3: "Document",
  4: "Liveness",
  5: "Document Back",
  6: "Liveness",
  7: "Document Back",
  8: "Misc Document",
  9: "ID Authority",
  10: "Misc Document",
  11: "ID Authority Document",
  12: "Potrait",
});

const Groups = Object.freeze({
  Document: [1, 3, 5, 7, 8, 10, 11],
  "Selfie and ID Authority": [0, 2, 9],
  Liveness: [4, 6],
  Potrait: [12],
});

function DisplayImages({ images = [] }) {
  const groupedImagesByType = {};
  const ungroupedImages = [];
  Object.entries(Groups).forEach(([groupName, groupImageTypes]) => {
    const subset = images
      .filter((image) =>
        groupImageTypes.includes(parseInt(image.image_type, 10)),
      )
      .sort((a, b) =>
        `${a.image_type}-${a.file_name}-${a.enrolled_image}`.localeCompare(
          `${b.image_type}-${b.file_name}-${b.enrolled_image}`,
        ),
      );
    if (subset.length > 0) {
      groupedImagesByType[groupName] = subset;
    }
  });

  // Identify ungrouped images
  images.forEach((image) => {
    if (
      !Object.values(Groups).flat().includes(parseInt(image.image_type, 10))
    ) {
      ungroupedImages.push(image);
    }
  });

  // Add ungrouped images to a new group if they exist
  if (ungroupedImages.length > 0) {
    groupedImagesByType.Ungrouped = ungroupedImages.sort((a, b) =>
      a.file_name.localeCompare(b.file_name),
    );
  }

  return (
    <>
      {Object.entries(groupedImagesByType).map(([groupName, imageGroup]) => (
        <div key={`image-group-${groupName}`}>
          <h4>{groupName} Images</h4>
          <div className="imagesContainer">
            {imageGroup.map((image) => (
              <div key={`div-${image.link}-${image.enrolled_image}`}>
                <img src={image.link} alt={image.file_name} className="image" />
                <span>
                  {image.enrolled_image ? (
                    <strong>Enrolled Image:</strong>
                  ) : null}{" "}
                  {humanReadableImageTypes[image.image_type] ??
                    image.image_type}{" "}
                  - <a href={image.link}>{image.file_name}</a>
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

function DisplayReviews({ reviews = [] }) {
  // Sort reviews by updated_at in descending order
  const sortedReviews = [...reviews].sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  );

  return (
    <div className="reviews-container">
      <h3>Job Reviews</h3>
      {sortedReviews.length === 0 ? (
        <p>No reviews available.</p>
      ) : (
        <table className="reviews-table">
          <thead>
            <tr>
              <th>Purpose</th>
              <th>Updated At</th>
              <th>Duration</th>
              <th>Author</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedReviews.map((review) => {
              const createdAt = new Date(review.created_at);
              const updatedAt = new Date(review.updated_at);
              const durationMilliseconds = updatedAt - createdAt;
              const durationMinutes = Math.floor(durationMilliseconds / 60000);
              const durationSeconds = (
                (durationMilliseconds % 60000) /
                1000
              ).toFixed(0);
              const duration =
                durationMinutes > 0
                  ? `${durationMinutes}m ${durationSeconds}s`
                  : `${durationSeconds}s`;

              return (
                <tr key={review.id}>
                  <td>
                    {review.complete ? (
                      <a
                        href={`/admin/reviews/${review.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {review.purpose}
                      </a>
                    ) : (
                      review.purpose
                    )}
                  </td>
                  <td>{updatedAt.toLocaleString()}</td>
                  <td>{review.complete ? duration : "-"}</td>
                  <td>{review.author || "N/A"}</td>
                  <td
                    className={
                      review.complete ? "status-complete" : "status-incomplete"
                    }
                  >
                    {review.complete ? "Complete" : "Incomplete"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

function DisplayCharges({ walletTransaction }) {
  if (!walletTransaction) {
    return;
  }
  return (
    <div>
      <p> Amount: {walletTransaction.amount}</p>
      <p> Balance After Transaction: {walletTransaction.current_balance}</p>
      <p> Billed Codes: {walletTransaction.result_codes.join(", ") || ""}</p>
      <p> Provider Cost: {walletTransaction.provider_cost || 0}</p>
    </div>
  );
}

function DisplayReRunButton({ job, amountCharged }) {
  const [showModal, setShowModal] = useState(false);

  return (
    biometricJobTypes.includes(parseInt(job.ran_as_job_type, 10)) && (
      <div className="smile-reference-show">
        <button
          onClick={() => setShowModal(!showModal)}
          className="btn btn-primary"
        >
          Re-run Job
        </button>
        <ModalWrapper
          isOpen={showModal}
          onClose={() => setShowModal(!showModal)}
          hideBackButton
        >
          <ReRun
            job={job}
            toggleModal={() => setShowModal(!showModal)}
            amountCharged={amountCharged}
          />
        </ModalWrapper>
      </div>
    )
  );
}

function JobsShow() {
  const [selectedJob, setSelectedJob] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const { environment } = useContext(DisplayEnvironment);
  const { id } = useParams();
  const history = useHistory();

  const [fileLinks, setFileLinks] = useState(undefined);
  const [isLoadingFileLinks, setIsLoadingFileLinks] = useState(false);

  const handleFileLinksFetch = async () => {
    setIsLoadingFileLinks(true);
    try {
      const links = await fetchJobFileLinks(id);
      setFileLinks(links.files);
    } catch (error) {
      console.error("Error fetching file links:", error);
    } finally {
      setIsLoadingFileLinks(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", `/job/${getEnvironment()}/${id}`);
    // Sets the environment based on the url to the current environment.
    // This fixes a bug on dev sandbox where the environment
    // is not always set when getData called.
    createInitialEnvironment();
    getData();
  }, [id, environment]);

  const getData = () => {
    fetchJob(id).then((resp) => {
      setSelectedJob(resp);
      setErrorMessage(resp.message);
    });
  };

  const goToJob = (id) => {
    history.push(`/admin/job/${getEnvironment()}/${id}`);
    setSelectedJob(undefined);
    getData();
  };

  if (!selectedJob) {
    return <div className="loader" />;
  }

  return (
    <>
      <ErrorAndMessageBar errors={errorMessage} />
      <DisplayTitle
        environment={getEnvironment()}
        jobId={selectedJob.job?.job_id}
        jobType={selectedJob.job?.ran_as_job_type}
        partnerId={selectedJob.job?.partner_id}
        productType={selectedJob.product_type}
      />
      <DisplayReRunButton
        job={selectedJob.job}
        amountCharged={selectedJob?.wallet_transaction?.amount}
      />
      <hr />
      <div className="smile-reference-show">
        <h3>Access logs</h3>
        <DisplayLogs logs={selectedJob.logs} />
        <h3>Smile References</h3>
        <DisplayReferences references={selectedJob.smile_references} />
        <DisplayReviews reviews={selectedJob.reviews} />
        <h3>Inference Server Responses</h3>
        <DisplayMLResults mlResults={selectedJob.ml_results} />
        <h3>Related Jobs</h3>
        <DisplayRelatedJobs
          relatedJobs={selectedJob.related_jobs}
          eventHandler={goToJob}
        />
        <h3>File Links (Biometric Jobs Only)</h3>
        {!fileLinks &&
        !isLoadingFileLinks &&
        biometricJobTypes.includes(selectedJob.job?.ran_as_job_type) ? (
          <button
            className="btn btn-primary button"
            type="button"
            onClick={handleFileLinksFetch}
          >
            Fetch File Links
          </button>
        ) : null}
        {isLoadingFileLinks ? <div>Loading...</div> : null}
        {fileLinks ? <DisplayFiles fileLinks={fileLinks} /> : null}
        <h3>Images</h3>
        <DisplayImages images={selectedJob.images} />
        <h3>Charges</h3>
        <DisplayCharges walletTransaction={selectedJob.wallet_transaction} />
      </div>

      <Link to="/admin/access_logs" className="btn btn-primary back-button">
        Back
      </Link>
    </>
  );
}

export default JobsShow;
export {
  DisplayCharges,
  DisplayImages,
  DisplayLogs,
  DisplayReRunButton,
  DisplayReviews,
  DisplayTitle,
};
